import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import {MainMenu} from "../components/menu/MainMenu";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import SessionService from "../features/session/SessionService";

export function DefaultLayout({children}) {
    const theme = useTheme()
    const atMostMdScreen = useMediaQuery(theme.breakpoints.down('md'))
    
    return (
        <>
            { !atMostMdScreen &&
            <div id="sidebar">
                <PersonIcon id="user-icon"/>
                <Button variant="text">Twoje dane</Button>
                <Button variant="contained" onClick={() => SessionService.logout().then(() => {})}>
                    Wyloguj
                </Button>
                <MainMenu/>
            </div>
            }
            
            <div id="main-wrapper">
                <div id="workspace">
                    { children }
                </div>
                <div id="bottom-navigation">
                </div>
            </div>
        </>
    )
}