import {DefaultLayout} from "../../layouts/DefaultLayout";
import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import InProgress from "../../components/progress/InProgress";
import {useParams} from "react-router";
import PartyService from "../../features/parties/PartyService";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import PartyLogins from "../../features/parties/sections/PartyLogins";
import Routes from "../../features/router/Routes";
import ErrorUtils from "../../utils/error/ErrorUtils";

export default function PartyDetailsPage() {
    const navigate = useNavigate()

    const [partyDetails, setPartyDetails] = useState(null)

    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const params = useParams()
    const locationMatch = useMatch(Routes.Parties.Index + Routes.Parties.Details);
    
    function updateData() {
        setInProgress(true)
        
        PartyService.getPartyDetails(params.partyId)
            .then(r => {
                setInProgress(false)
                setPartyDetails(r.data)
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(e.response.data.message)
            })
    }

    useEffect(() => {
        if (!locationMatch) {
            return
        }
        
        updateData()
    }, [locationMatch])

    if (!locationMatch) {
        return <Outlet/>
    }
    
    function formatPersonName() {
        if (((!partyDetails.party.firstName) || (partyDetails.party.firstName === '')) 
            && ((!partyDetails.party.lastName) || (partyDetails.party.lastName === ''))) {
            return '(Brak danych)'
        }
        
        return (partyDetails.party.lastName ?? '') + ' ' + (partyDetails.party.firstName ?? '')
    }

    function formatAddress() {
        const address = partyDetails.party.address
        
        if (!address) {
            return '(Brak danych)'
        }
        
        let line1 = ((address.street ?? '') + ' ' + (address.houseNumber ?? '')).trim()
        let line2 = ((address.postalCode ?? '') + ' ' + (address.city ?? '')).trim()
        return (
            <>
                { line1 }
                { line1 && line2 && <><br/></>}
                { line2 }
            </>
        )
    }
    
    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Szczegóły podmiotu
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        { partyDetails &&
                            <>
                                <section>
                                    <Box sx={{ borderRadius: 2, bgcolor: 'background.paper'}}>
                                        <List>
                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="sectionHeader">Dane podmiotu</Typography>}/>
                                            </ListItem>

                                            <Divider/>

                                            { partyDetails.party.partyType === 'person' &&
                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">Nazwisko i imię</Typography>}
                                                    secondary={<Typography variant="value">{ formatPersonName() }</Typography>}/>
                                            </ListItem>
                                            }

                                            { partyDetails.party.partyType === 'company' &&
                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">Nazwa firmy</Typography>}
                                                    secondary={<Typography variant="value">{ partyDetails.party.companyName ?? '(Brak danych)' }</Typography>}/>
                                            </ListItem>
                                            }

                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">Telefon</Typography>}
                                                    secondary={<Typography variant="value">{ partyDetails.party.phone?.number ?? '(Brak danych)' }</Typography>}/>
                                            </ListItem>

                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">E-mail</Typography>}
                                                    secondary={<Typography variant="value">{ partyDetails.party.email?.address ?? '(Brak danych)' }</Typography>}/>
                                            </ListItem>

                                            { partyDetails.party.partyType === 'company' &&
                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">NIP</Typography>}
                                                    secondary={<Typography variant="value">{ partyDetails.party.nip?.number ?? '(Brak danych)' }</Typography>}/>
                                            </ListItem>
                                            }

                                            { partyDetails.party.partyType === 'company' &&
                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">REGON</Typography>}
                                                    secondary={<Typography variant="value">{ partyDetails.party.regon?.number ?? '(Brak danych)' }</Typography>}/>
                                            </ListItem>
                                            }

                                            { partyDetails.party.partyType === 'company' &&
                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">KRS</Typography>}
                                                    secondary={<Typography variant="value">{ partyDetails.party.krs?.number ?? '(Brak danych)' }</Typography>}/>
                                            </ListItem>
                                            }

                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">Adres</Typography>}
                                                    secondary={<Typography variant="value">{ formatAddress() }</Typography>}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </section>
                                
                                <PartyLogins partyId={params.partyId} logins={partyDetails.partyLogins}
                                             inProgress={() => setInProgress(true)}
                                             onLoginDeleted={() => {
                                                 setInProgress(false)
                                                 updateData()
                                             }}
                                             onError={(err) => {
                                                 setInProgress(false)
                                                 setShowError(true)
                                                 setErrorMessage(ErrorUtils.getMessage(err))
                                                 updateData()
                                             }}/>
                            </>
                        }
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}