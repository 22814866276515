import React, {useRef, useState} from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SessionService from "../features/session/SessionService";

export function LoginPage() {
    function createForm()
    {
        return {
            username: {
                error: false,
                helperText: ''
            },
            password: {
                error: false,
                helperText: ''
            },
            loginButton: {
                disabled: false
            }
        }
    }
    
    const [form, setForm] = useState(createForm())
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const usernameRef = useRef('')
    const passwordRef = useRef('')
    
    function validate() {
        let form = createForm()
        let isValid = true
        
        if (usernameRef.current.value.trim() === '') {
            isValid = false
            form.username.error = true
            form.username.helperText = 'Pole nie może być puste';
        }

        if (passwordRef.current.value.trim() === '') {
            isValid = false
            form.password.error = true
            form.password.helperText = 'Pole nie może być puste';
        }
        
        setForm(form)
        return isValid
    }
    
    function loginClickHandler(e){
        e.preventDefault()
        
        if (validate()) {
            let form = createForm()
            form.loginButton.disabled = true
            setSnackBarOpen(false)
            setForm(form)
            
            SessionService.login(usernameRef.current.value, passwordRef.current.value)
                .then(() => {
                    let form = createForm()
                    form.loginButton.disabled = false
                    setForm(form)
                })
                .catch(e => {
                    let form = createForm()
                    form.loginButton.disabled = false
                    setForm(form)
                    setSnackBarOpen(true)
                    console.error(e)
                })
        }
    }
    
    return (
        <>
            <Container id="auth-form-wrapper">
                <Box component="form" noValidate autoComplete="off" display="block">
                    <Grid container spacing={4} width={280}>
                        <Grid item xs={12}>
                            <Typography variant="h1" sx={{ fontSize: 28 }}>Logowanie</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="username" name="username" label="Nazwa użytkownika"
                                       variant="standard" fullWidth={true} inputRef={usernameRef}
                                       error={form.username.error} helperText={form.username.helperText}/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="password" name="password" label="Hasło" variant="standard"
                                       type="password" fullWidth={true} inputRef={passwordRef}
                                       error={form.password.error} helperText={form.password.helperText}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Button disabled={form.loginButton.disabled} type={"submit"} variant="contained" onClick={(e) => loginClickHandler(e)}>Zaloguj</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            
            <Snackbar open={snackBarOpen} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
                      onClose={() => { setSnackBarOpen(false) }}>
                <Alert severity="error">Logowanie nie powiodło się!</Alert>
            </Snackbar>
        </>
    )
}