import Button from "@mui/material/Button";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";

export function UploadFile({inputRef, error, helperText}) {
    const [fileName, setFileName] = useState('')
    
    return (
        <>
            <div className="upload-file">
                <TextField label="Wybierz plik" fullWidth={true} value={ fileName } error={error} helperText={helperText}/>
                <Button variant="outlined" component="label" className="browse">
                    Przeglądaj
                    <input ref={inputRef} hidden type="file" onChange={(e) => {
                        setFileName(e.target.files.item(0).name)
                    }}/>
                </Button>
            </div>
        </>
    )
}