import React, {useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import {useSelector} from "react-redux";
import ListConfig, {FilterConfig, SortConfig} from "../../components/list/ListConfig";
import PartyListFilterConfig from "../../features/parties/list/PartyListFilterConfig";
import PartyListSortConfig from "../../features/parties/list/PartyListSortConfig";
import PartyListDataManager from "../../features/parties/list/PartyListDataManager";
import StandardListView, {StandardListViewItems} from "../../components/list/StandardListView";
import {store} from '../../redux/store'
import {filterByPhraseChanged, phraseChanged, offsetChanged} from "../../features/parties/list/partyListSlice";
import {useDebouncedCallback} from "use-debounce";
import {useNavigate, useMatch, Outlet} from "react-router-dom";
import Routes from "../../features/router/Routes";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import PartyService from "../../features/parties/PartyService";
import ErrorUtils from "../../utils/error/ErrorUtils";

function PartyListItem(props) {
    const navigate = useNavigate()

    function formatPartyType() {
        if (props.data.partyType === 'person') {
            return 'Osoba fizyczna'
        }

        if (props.data.partyType === 'company') {
            return 'Firma'
        }

        return 'Nieznany typ podmiotu'
    }
    
    function formatGardenList() {
        const gardens =  props.data.gardens
        let result = ''
        
        for (let i = 0; i < gardens.length; i++) {
            result += gardens[i].name
            
            if (i < gardens.length - 1) {
                result += ', '
            }
        }
        
        return result
    }
    
    return (
        <>
            <ListItem secondaryAction={
                <>
                    <IconButton onClick={() => {
                        navigate('.' + Routes.Parties.Edit.replace(':partyId', props.data.id))
                    }}>
                        <EditIcon/>
                    </IconButton>

                    <IconButton onClick={() => props.onDeleteClick(props.data)}>
                        <DeleteIcon/>
                    </IconButton>

                    <IconButton onClick={() => {
                        navigate('.' + Routes.Parties.Details.replace(':partyId', props.data.id))
                    }}>
                        <MoreHorizIcon/>
                    </IconButton>
                </>
            }>
                <ListItemText primary={ props.data.name } secondary={
                    <>
                        { formatPartyType() }<br/>
                        { formatGardenList() }
                    </>
                }>
                </ListItemText>
            </ListItem>

            <Divider/>
        </>
    )
}

export function Parties() {
    const navigate = useNavigate()
    const data = useSelector((state) => state.partyList.data)
    const [currentItem, setCurrentItem] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const loadData = useDebouncedCallback(() => {
        PartyListDataManager.load()
    }, 1000)
    
    useEffect(() => {
        setCurrentItem(null)
        store.dispatch(offsetChanged(0))
        PartyListDataManager.load()
    }, [])
    
    if (!useMatch(Routes.Parties.Index)) {
        return <Outlet/>
    }

    return (
        <>
            <StandardListView 
                title={"Podmioty"} 
                initialize={() => {
                    return {
                        showSearchInput: store.getState().partyList.requestParams.filterByPhrase,
                        searchPhrase: store.getState().partyList.requestParams.phrase
                    }
                }}
                onSearchButtonClick={(showSearchInput) => {
                    store.dispatch(filterByPhraseChanged(showSearchInput))
                    loadData()
                }}
                onSearchPhraseChange={(e) => {
                    store.dispatch(phraseChanged(e.target.value))
                    loadData()
                }}
                onAddButtonClick={() => {
                    navigate('.' + Routes.Parties.Create)
                }}
                loadData={() => loadData()}
                data={data}>
                <StandardListViewItems>
                    {
                        data.items.map((i) => (
                            <PartyListItem key={i.id} data={i} onDeleteClick={(i) => {
                                setCurrentItem(i)
                                setOpenConfirmDialog(true)
                            }}/>
                        ))
                    }
                </StandardListViewItems>

                <ListConfig>
                    <FilterConfig>
                        <PartyListFilterConfig/>
                    </FilterConfig>

                    <SortConfig>
                        <PartyListSortConfig/>
                    </SortConfig>
                </ListConfig>
            </StandardListView>
            
            <Dialog open={openConfirmDialog}>
                <DialogTitle>Potwierdź usunięcie zasobu</DialogTitle>
                <DialogContent dividers>
                    Czy na pewno chcesz usunąc podmiot {currentItem?.name}?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setOpenConfirmDialog(false)
                    }}>
                        Anuluj
                    </Button>
                    
                    <Button onClick={() => {
                        setOpenConfirmDialog(false)
                        
                        PartyService.deleteParty(currentItem.id)
                            .then(() => {
                                console.info("The party #" + currentItem.id + " has been deleted.")
                                store.dispatch(offsetChanged(0))
                                PartyListDataManager.load()
                            })
                            .catch(err => {
                                console.error(err)
                                setShowError(true)
                                setErrorMessage(ErrorUtils.getMessage(err))
                            })
                    }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <FloatingAlert open={showError}
                           severity="error"
                           message={errorMessage}
                           viewHasActionButton={true}
                           onClose={() => setShowError(false)}/>
        </>
    )
}