import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {DefaultLayout} from "../../../layouts/DefaultLayout";
import React, {useRef} from "react";
import {useNavigate} from "react-router";
import {Editor} from "@tinymce/tinymce-react";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import InProgress from "../../../components/progress/InProgress";
import FloatingAlert from "../../../components/notifications/FloatingAlert";

export function EditHtmlTextItem(props) {
    const editorRef = useRef(null)
    const navigate = useNavigate()
    
    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Edycja tekstu HTML
                            </Typography>

                            <IconButton size="large" color="inherit" edge="end"
                                        aria-label="Zapisz" aria-haspopup="false"
                                        onClick={() => {
                                            props.onSaveClick(editorRef.current)
                                        }}>
                                <SaveIcon/>
                            </IconButton>

                            <IconButton size="large" color="inherit" edge="end"
                                        aria-label="Zatwierdź" aria-haspopup="false"
                                        onClick={() => {
                                            props.onCommitClick(editorRef.current)
                                        }}>
                                <CheckIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content" className="editor">
                        <Editor tinymceScriptSrc="/lib/tinymce/js/tinymce/tinymce.min.js" 
                                onInit={(evt, editor) => {
                                    editor.focus()
                                    editorRef.current = editor
                                }}
                                init={{
                                    language: 'pl', 
                                    height: '100%', 
                                    menubar: false, 
                                    resize: false, 
                                    plugins: [
                                        'lists', 'link'
                                    ], 
                                    style_formats: [
                                        {
                                            title: 'Nagłówki', items: [
                                                {
                                                    title: 'Nagłówek 2', 
                                                    format: 'h2'
                                                }, 
                                                {
                                                    title: 'Nagłówek 3', 
                                                    format: 'h3'
                                                }
                                            ]
                                        }
                                    ], 
                                    toolbar: [
                                        'bold italic underline link unlink forecolor removeformat bullist numlist', 
                                        'styles'
                                    ], 
                                    statusbar: false,
                                    init_instance_callback: (editor) => {
                                        if (props.onInit) {
                                            props.onInit(editor)
                                        }
                                    }
                                }}/>
                    </div>
                </div>

                <InProgress open={props.inProgress || !editorRef.current}/>

                <FloatingAlert open={props.showSuccess}
                               severity="success"
                               message={props.successMessage}
                               onClose={() => props.onSuccessAlertClose()}/>
                
                <FloatingAlert open={props.showError}
                               severity="error"
                               message={props.errorMessage}
                               onClose={() => props.onErrorAlertClose()}/>
            </DefaultLayout>
        </>
    )
}