import axios from "axios";

const NewsService = {
    createFindNewsDefaultParams: function() {
        return {
            filterByPhrase: false,
            filterByState: false,
            phrase: '',
            isActive: true,
            offset: 0,
            limit: 100,
            sortBy: 'created-date',
            sortDirection: 'desc'
        }
    },

    findNews: function (params) {
        return axios.get('/api/news', {
            params: {
                'filter-by-phrase': params.filterByPhrase,
                'filter-by-state': params.filterByState,
                'phrase': params.phrase,
                'is-active': params.isActive,
                'offset': params.offset,
                'limit': params.limit,
                'sort-by': params.sortBy,
                'sort-direction': params.sortDirection
            }
        });
    },

    createNews: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/news/create', data)
    },

    getNews: function(newsId) {
        if (!newsId || typeof newsId != 'string') {
            throw new Error('The news id argument is not valid.')
        }

        return axios.get('/api/news/get/' + newsId)
    },

    updateNews: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/news/update', data)
    },

    deleteNews: function(newsId) {
        if (!newsId || typeof newsId != 'string') {
            throw new Error('The news id argument is not valid.')
        }

        return axios.delete('/api/news/delete/' + newsId)
    },

    getContentItem: function(newsId, contentItemId) {
        if (!newsId || typeof newsId != 'string') {
            throw new Error('The news id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.get('/api/news/' + newsId + '/content/item/' + contentItemId)
    },

    moveContentItemUp: function(newsId, contentItemId) {
        if (!newsId || typeof newsId != 'string') {
            throw new Error('The news id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.put('/api/news/' + newsId + '/content/item/' + contentItemId + '/move-up')
    },

    moveContentItemDown: function(newsId, contentItemId) {
        if (!newsId || typeof newsId != 'string') {
            throw new Error('The news id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.put('/api/news/' + newsId + '/content/item/' + contentItemId + '/move-down')
    },

    deleteContentItem: function(newsId, contentItemId) {
        if (!newsId || typeof newsId != 'string') {
            throw new Error('The news id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }
        
        return axios.delete('/api/news/' + newsId + '/content/item/' + contentItemId)
    },

    addHtmlTextContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/news/content/item/add-html-text', data)
    },

    updateHtmlTextContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/news/content/item/update-html-text', data)
    },

    addImageContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }
        
        let formData = new FormData()
        formData.append('newsId', data.newsId)
        formData.append('alt', data.alt)
        formData.append('file', data.file)

        return axios.post('/api/news/content/item/add-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    updateImageContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/news/content/item/update-image', data)
    },
}

export default NewsService