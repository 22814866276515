import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import React, {useState} from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import SessionService from "../../features/session/SessionService";

export default function CommonButtons() {
    const [isUserMenuOpen, setUserMenuOpen] = useState(false)
    const [userMenuAnchorElement, setUserMenuAnchorElement] = useState(null)
    const theme = useTheme()
    const atMostMdScreen = useMediaQuery(theme.breakpoints.down('md'))
    
    return (
        <>
            { atMostMdScreen &&
            <IconButton size="large" color="inherit" edge="end"
                        aria-label="Użytkownik" aria-haspopup="true"
                        onClick={(event) => {
                            setUserMenuAnchorElement(event.currentTarget)
                            setUserMenuOpen(!isUserMenuOpen)
                        }}>
                <PersonIcon/>
            </IconButton>
            }
            
            <Menu open={isUserMenuOpen} 
                  anchorEl={userMenuAnchorElement}
                  keepMounted
                  anchorOrigin={{
                      vertical: 'bottom', 
                      horizontal: 'right'
                  }} 
                  transformOrigin={{
                      vertical: 'top', 
                      horizontal: 'right'
                  }} 
                  onClose={() => setUserMenuOpen(false)}>
                <MenuItem onClick={() => {
                    setUserMenuOpen(false)
                }}>
                    Twoje konto
                </MenuItem>
                
                <MenuItem onClick={() => {
                    setUserMenuOpen(false)
                    SessionService.logout().then(() => {})
                }}>
                    Wyloguj
                </MenuItem>
            </Menu>
        </>
    )
}