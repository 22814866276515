import {DefaultLayout} from "../../layouts/DefaultLayout";
import React, {useEffect, useRef, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InProgress from "../../components/progress/InProgress";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import {store} from "../../redux/store";
import {offsetChanged} from "../../features/parties/list/partyListSlice";
import PartyService from "../../features/parties/PartyService";
import PartyListDataManager from "../../features/parties/list/PartyListDataManager";
import Validator from "../../features/validation/Validator";
import {useParams} from "react-router";

export default function CreatePartyPage() {
    const navigate = useNavigate()

    // Party
    const [partyType, setPartyType] = useState('person')
    const phoneRef = useRef(null)
    const emailRef = useRef(null)
    const streetRef = useRef(null)
    const houseNumberRef = useRef(null)
    const postalCodeRef = useRef(null)
    const cityRef = useRef(null)

    // Person
    const firstNameRef = useRef(null)
    const lastNameRef = useRef(null)

    // Company
    const companyNameRef = useRef(null)
    const nipRef = useRef(null)
    const regonRef = useRef(null)
    const krsRef = useRef(null)

    // Others
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const params = useParams()

    useEffect(() => {
        setInProgress(true)

        PartyService.getParty(params.partyId)
            .then(r => {
                setInProgress(false)
                setPartyType(r.data.partyType)
                
                if (r.data.partyType === 'person') {
                    firstNameRef.current.value = r.data.firstName ?? ''
                    lastNameRef.current.value = r.data.lastName ?? ''
                }

                if (r.data.partyType === 'company') {
                    companyNameRef.current.value = r.data.companyName ?? ''
                    nipRef.current.value = r.data.nip ? r.data.nip.number : ''
                    regonRef.current.value = r.data.regon ? r.data.regon.number : ''
                    krsRef.current.value = r.data.krs ? r.data.krs.number : ''
                }

                phoneRef.current.value = r.data.phone ? r.data.phone.number : ''
                emailRef.current.value = r.data.email ? r.data.email.address : ''
                
                streetRef.current.value = r.data.address && r.data.address.street ? r.data.address.street : ''
                houseNumberRef.current.value = r.data.address && r.data.address.houseNumber ? r.data.address.houseNumber : ''
                postalCodeRef.current.value = r.data.address && r.data.address.postalCode ? r.data.address.postalCode : ''
                cityRef.current.value = r.data.address && r.data.address.city ? r.data.address.city : ''
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(e.response.data.message)
            })
    }, [params.fileId])

    function createFormData() {
        return {
            // Party
            phone: {
                error: false,
                helperText: ''
            },
            email: {
                error: false,
                helperText: ''
            },
            street: {
                error: false,
                helperText: ''
            },
            houseNumber: {
                error: false,
                helperText: ''
            },
            postalCode: {
                error: false,
                helperText: ''
            },
            city: {
                error: false,
                helperText: ''
            },
            remarks: {
                error: false,
                helperText: ''
            },

            // Person
            firstName: {
                error: false,
                helperText: ''
            },
            lastName: {
                error: false,
                helperText: ''
            },

            // Company
            companyName: {
                error: false,
                helperText: ''
            },
            nip: {
                error: false,
                helperText: ''
            },
            regon: {
                error: false,
                helperText: ''
            },
            krs: {
                error: false,
                helperText: ''
            },
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true

        // Party
        const phone = phoneRef.current.value
        const email = emailRef.current.value
        const street = streetRef.current.value
        const houseNumber = houseNumberRef.current.value
        const postalCode = postalCodeRef.current.value
        const city = cityRef.current.value

        // Person
        const firstName = firstNameRef.current.value
        const lastName = lastNameRef.current.value

        // Company
        const companyName = companyNameRef.current.value
        const nip = nipRef.current.value
        const regon = regonRef.current.value
        const krs = krsRef.current.value

        if (partyType === 'person') {
            if (firstName.trim().length === 0) {
                newFormData.firstName.error = true
                newFormData.firstName.helperText = 'Imię nie może być puste'
                isValid = false
            }

            if (lastName.trim().length === 0) {
                newFormData.lastName.error = true
                newFormData.lastName.helperText = 'Nazwisko nie może być puste'
                isValid = false
            }
        }

        if (partyType === 'company') {
            if (companyName.trim().length === 0) {
                newFormData.companyName.error = true
                newFormData.companyName.helperText = 'Nazwa firmy nie może być pusta'
                isValid = false
            }

            if (!Validator.validateNip(nip)) {
                newFormData.nip.error = true
                newFormData.nip.helperText = 'Numer NIP powinien składać się z 10 cyfr'
                isValid = false
            }

            if (!Validator.validateRegon(regon)) {
                newFormData.regon.error = true
                newFormData.regon.helperText = 'Numer REGON powinien składać się z 9 lub 14 cyfr'
                isValid = false
            }

            if (!Validator.validateKrs(krs)) {
                newFormData.krs.error = true
                newFormData.krs.helperText = 'Numer KRS powinien składać się z 10 cyfr'
                isValid = false
            }
        }

        if (!Validator.validatePhone(phone)) {
            newFormData.phone.error = true
            newFormData.phone.helperText = 'Numer powinien składać się wyłącznie z 11 cyfr w tym kodu kraju (np. 48100200300)'
            isValid = false
        }

        if (!Validator.validateEmail(email)) {
            newFormData.email.error = true
            newFormData.email.helperText = 'Podaj poprawny adres e-mail'
            isValid = false
        }

        if (street.trim().length > 0
            || houseNumber.trim().length > 0
            || postalCode.trim().length > 0
            || city.trim().length > 0) {

            if (street.trim().length === 0) {
                newFormData.street.error = true
                newFormData.street.helperText = 'Należy podać pełny adres lub nie podawać go wcale. Podaj ulicę lub usuń inne dane związane z adresem.'
                isValid = false
            }

            if (houseNumber.trim().length === 0) {
                newFormData.houseNumber.error = true
                newFormData.houseNumber.helperText = 'Należy podać pełny adres lub nie podawać go wcale. Podaj numer lokalu lub usuń inne dane związane z adresem.'
                isValid = false
            }

            if (!Validator.validatePostalCode(postalCode, true)) {
                newFormData.postalCode.error = true
                newFormData.postalCode.helperText = 'Należy podać pełny adres lub nie podawać go wcale. Podaj kod pocztowy w formacie XY-XYZ lub usuń inne dane związane z adresem.'
                isValid = false
            }

            if (city.trim().length === 0) {
                newFormData.city.error = true
                newFormData.city.helperText = 'Należy podać pełny adres lub nie podawać go wcale. Podaj miejscowość lub usuń inne dane związane z adresem.'
                isValid = false
            }
        }

        setFormData(newFormData)
        return isValid
    }

    function updateParty() {
        if (!validate()) {
            return
        }

        setInProgress(true)

        PartyService.updateParty({
            // Party
            partyId: params.partyId,
            partyType: partyType,
            phone: phoneRef.current.value,
            email: emailRef.current.value,
            street: streetRef.current.value,
            houseNumber: houseNumberRef.current.value,
            postalCode: postalCodeRef.current.value,
            city: cityRef.current.value,

            // Person
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,

            // Company
            companyName: companyNameRef.current.value,
            nip: nipRef.current.value,
            regon: regonRef.current.value,
            krs: krsRef.current.value
        }).then(() => {
            // TODO: dodac informacje o sukcesie
            setInProgress(false)
            store.dispatch(offsetChanged(0))
            PartyListDataManager.load()
            navigate('..')
        }).catch(err => {
            console.error(err)
            setInProgress(false)
            setShowError(true)
            setErrorMessage(err.response.data.message)
        })
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Nowy podmiot
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane Podmiotu</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sx={{ display: partyType === 'person' ? 'block' : 'none' }}>
                                    <TextField label="Imię"
                                               fullWidth={true}
                                               inputRef={firstNameRef}
                                               error={formData.firstName.error}
                                               helperText={formData.firstName.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12} sx={{ display: partyType === 'person' ? 'block' : 'none' }}>
                                    <TextField label="Nazwisko"
                                               fullWidth={true}
                                               inputRef={lastNameRef}
                                               error={formData.lastName.error}
                                               helperText={formData.lastName.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12} sx={{ display: partyType === 'company' ? 'block' : 'none' }}>
                                    <TextField label="Nazwa firmy"
                                               fullWidth={true}
                                               inputRef={companyNameRef}
                                               error={formData.companyName.error}
                                               helperText={formData.companyName.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12} sx={{ display: partyType === 'company' ? 'block' : 'none' }}>
                                    <TextField label="NIP"
                                               fullWidth={true}
                                               inputRef={nipRef}
                                               error={formData.nip.error}
                                               helperText={formData.nip.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12} sx={{ display: partyType === 'company' ? 'block' : 'none' }}>
                                    <TextField label="REGON"
                                               fullWidth={true}
                                               inputRef={regonRef}
                                               error={formData.regon.error}
                                               helperText={formData.regon.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12} sx={{ display: partyType === 'company' ? 'block' : 'none' }}>
                                    <TextField label="KRS"
                                               fullWidth={true}
                                               inputRef={krsRef}
                                               error={formData.krs.error}
                                               helperText={formData.krs.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Telefon"
                                               fullWidth={true}
                                               inputRef={phoneRef}
                                               error={formData.phone.error}
                                               helperText={formData.phone.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Email"
                                               fullWidth={true}
                                               inputRef={emailRef}
                                               error={formData.email.error}
                                               helperText={formData.email.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Ulica"
                                               fullWidth={true}
                                               inputRef={streetRef}
                                               error={formData.street.error}
                                               helperText={formData.street.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Numer lokalu"
                                               fullWidth={true}
                                               inputRef={houseNumberRef}
                                               error={formData.houseNumber.error}
                                               helperText={formData.houseNumber.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Kod pocztowy"
                                               fullWidth={true}
                                               inputRef={postalCodeRef}
                                               error={formData.postalCode.error}
                                               helperText={formData.postalCode.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Miejscowość"
                                               fullWidth={true}
                                               inputRef={cityRef}
                                               error={formData.city.error}
                                               helperText={formData.city.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        updateParty()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}