import List from "@mui/material/List";
import React from "react";
import AvailableFilters from "../../../components/list/filter/AvailableFilters";
import ActiveFilters from "../../../components/list/filter/ActiveFilters";
import Filter from "../../../components/list/filter/Filter";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { store } from "../../../redux/store"
import {useSelector} from "react-redux";
import {
    filterByNumberOfGardensChanged,
    filterByPartyTypeChanged, numberOfGardensFromChanged, numberOfGardensToChanged,
    partyTypeChanged
} from "./partyListSlice";
import ListItem from "@mui/material/ListItem";
import PartyListDataManager from './PartyListDataManager'
import { useDebouncedCallback } from "use-debounce";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";

export default function PartyListFilterConfig () {
    const requestParams = useSelector((state) => state.partyList.requestParams)
    const loadData = useDebouncedCallback(() => {
        PartyListDataManager.load()
    }, 1000)
    
    if (!requestParams) {
        return <></>
    }
    
    return (
        <>
            <List>
                <AvailableFilters>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(filterByPartyTypeChanged(!requestParams.filterByPartyType))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={requestParams.filterByPartyType}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'filter-by-party-type'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="filter-by-party-type" primary="Typ podmiotu"/>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(filterByNumberOfGardensChanged(!requestParams.filterByNumberOfGardens))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={requestParams.filterByNumberOfGardens}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'filter-by-number-of-gardens'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="filter-by-number-of-gardens" primary="Liczba działek"/>
                        </ListItemButton>
                    </ListItem>
                </AvailableFilters>

                <ActiveFilters>
                    { requestParams.filterByPartyType &&
                    <Filter name="Typ podmiotu">
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(partyTypeChanged('person'))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.partyType === 'person'}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'person'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="person" primary="Osoba fizyczna"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(partyTypeChanged('company'))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.partyType === 'company'}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'company'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="company" primary="Firma"/>
                            </ListItemButton>
                        </ListItem>
                    </Filter>
                    }

                    { requestParams.filterByNumberOfGardens &&
                    <Filter name="Liczba działek">
                        <ListItem>
                            <TextField label="Liczba działek - od" value={requestParams.numberOfGardensFrom} onChange={(e) => {
                                let val = e.target.value
                                if (val === '') {
                                    store.dispatch(numberOfGardensFromChanged(''))
                                    loadData()
                                } else if (/^[0-9]*$/.test(val)) {
                                    store.dispatch(numberOfGardensFromChanged(val))
                                    loadData()
                                }
                            }} InputLabelProps={{
                                shrink: true
                            }}/>
                        </ListItem>

                        <ListItem>
                            <TextField label="Liczba działek - do" value={requestParams.numberOfGardensTo} onChange={(e) => {
                                let val = e.target.value
                                if (val === '') {
                                    store.dispatch(numberOfGardensToChanged(''))
                                    loadData()
                                } else if (/^[0-9]*$/.test(val)) {
                                    store.dispatch(numberOfGardensToChanged(val))
                                    loadData()
                                }
                            }} InputLabelProps={{
                                shrink: true
                            }}/>
                        </ListItem>
                    </Filter>
                    }
                </ActiveFilters>
            </List>
        </>
    )
}