import { createSlice } from '@reduxjs/toolkit'
import FileService from "../FileService";

const initialState = {
    data: {
        totalItems: 0,
        items: []
    },
    requestParams: FileService.createFindFilesDefaultParams()
}

export const fileListSlice = createSlice({
    name: 'fileList',
    initialState,
    reducers: {
        offsetChanged: (state, action) => {
            state.requestParams.offset = 0
        },
        filterByNameChanged: (state, action) => {
            state.requestParams.filterByName = action.payload
            state.requestParams.offset = 0
        },
        filterByStateChanged: (state, action) => {
            state.requestParams.filterByState = action.payload
            state.requestParams.offset = 0
        },
        filterByPinningChanged: (state, action) => {
            state.requestParams.filterByPinning = action.payload
            state.requestParams.offset = 0
        },
        namePatternChanged: (state, action) => {
            state.requestParams.namePattern = action.payload
            state.requestParams.offset = 0
        },
        isActiveChanged: (state, action) => {
            state.requestParams.isActive = action.payload
            state.requestParams.offset = 0
        },
        isPinnedChanged: (state, action) => {
            state.requestParams.isPinned = action.payload
            state.requestParams.offset = 0
        },
        sortByChanged: (state, action) => {
            state.requestParams.sortBy = action.payload
            state.requestParams.offset = 0
        },
        sortDirectionChanged: (state, action) => {
            state.requestParams.sortDirection = action.payload
            state.requestParams.offset = 0
        },
        dataChanged: (state, action) => {
            state.data = action.payload
            state.requestParams.offset = state.data.items.length
        }
    }
})

export const { 
    offsetChanged,
    filterByNameChanged,
    filterByStateChanged,
    filterByPinningChanged,
    namePatternChanged,
    isActiveChanged,
    isPinnedChanged,
    sortByChanged,
    sortDirectionChanged,
    dataChanged
} = fileListSlice.actions

export default fileListSlice.reducer