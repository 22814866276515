const ArrayUtils = {
    merge: function (array1, array2, idConsumer) {
        if (!array1) {
            console.error('First array should be defined.')
            throw new Error('First array should be defined.')
        }

        if (!array2) {
            console.error('Second array should be defined.')
            throw new Error('Second array should be defined.')
        }

        if (!idConsumer) {
            console.error('Id consumer should be defined.')
            throw new Error('Id consumer should be defined.')
        }
        
        const map = {}
        
        for (let i = 0; i < array2.length; i++) {
            if (!array2[i]) {
                console.error('Item should be defined.')
                throw new Error('Item should be defined.')
            }
            
            const id = idConsumer(array2[i])
            
            if (!id) {
                console.error('Item id should be defined.')
                throw new Error('Item id should be defined.')
            }
            
            map[id] = array2[i]
        }
        
        let result = []
        let excludedMap = {}
        
        for (let i = 0; i < array1.length; i++) {
            if (!array1[i]) {
                console.error('Item should be defined.')
                throw new Error('Item should be defined.')
            }
            
            const id = idConsumer(array1[i])

            if (!id) {
                console.error('Item id should be defined.')
                throw new Error('Item id should be defined.')
            }
            
            if (id in map) {
                result.push(map[id])
                excludedMap[id] = id
                continue
            }

            result.push(array1[i])
        }

        for (let i = 0; i < array2.length; i++) {
            const id = idConsumer(array2[i])
            
            if (id in excludedMap) {
                continue
            }

            result.push(array2[i])
        }
        
        return result
    }
}

export default ArrayUtils