import { createSlice } from '@reduxjs/toolkit'
import MeterService from "../MeterService";

const initialState = {
    data: {
        totalItems: 0,
        items: []
    },
    requestParams: MeterService.createFindMetersDefaultParams()
}

export const meterListSlice = createSlice({
    name: 'meterList',
    initialState,
    reducers: {
        offsetChanged: (state, action) => {
            state.requestParams.offset = 0
        },
        filterByPhraseChanged: (state, action) => {
            state.requestParams.filterByPhrase = action.payload
            state.requestParams.offset = 0
        },
        filterByStateChanged: (state, action) => {
            state.requestParams.filterByState = action.payload
            state.requestParams.offset = 0
        },
        filterByTypeChanged: (state, action) => {
            state.requestParams.filterByType = action.payload
            state.requestParams.offset = 0
        },
        filterByLastMeasurementTimeChanged: (state, action) => {
            state.requestParams.filterByLastMeasurementTime = action.payload
            state.requestParams.offset = 0
        },
        phraseChanged: (state, action) => {
            state.requestParams.phrase = action.payload
            state.requestParams.offset = 0
        },
        isActiveChanged: (state, action) => {
            state.requestParams.isActive = action.payload
            state.requestParams.offset = 0
        },
        typeChanged: (state, action) => {
            state.requestParams.type = action.payload
            state.requestParams.offset = 0
        },
        lastMeasurementTimeFromChanged: (state, action) => {
            state.requestParams.lastMeasurementTimeFrom = action.payload
            state.requestParams.offset = 0
        },
        lastMeasurementTimeThruChanged: (state, action) => {
            state.requestParams.lastMeasurementTimeThru = action.payload
            state.requestParams.offset = 0
        },
        sortByChanged: (state, action) => {
            state.requestParams.sortBy = action.payload
            state.requestParams.offset = 0
        },
        sortDirectionChanged: (state, action) => {
            state.requestParams.sortDirection = action.payload
            state.requestParams.offset = 0
        },
        dataChanged: (state, action) => {
            state.data = action.payload
            state.requestParams.offset = state.data.items.length
        }
    }
})

export const {
    offsetChanged,
    filterByPhraseChanged,
    filterByStateChanged,
    filterByTypeChanged,
    filterByLastMeasurementTimeChanged,
    phraseChanged,
    isActiveChanged,
    typeChanged,
    lastMeasurementTimeFromChanged,
    lastMeasurementTimeThruChanged,
    sortByChanged,
    sortDirectionChanged,
    dataChanged
} = meterListSlice.actions

export default meterListSlice.reducer