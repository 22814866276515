import React, {useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useSelector} from "react-redux";
import ListConfig, {FilterConfig, SortConfig} from "../../components/list/ListConfig";
import FileListFilterConfig from "../../features/files/list/FileListFilterConfig";
import FileListSortConfig from "../../features/files/list/FileListSortConfig";
import FileListDataManager from "../../features/files/list/FileListDataManager";
import StandardListView, {StandardListViewItems} from "../../components/list/StandardListView";
import {store} from '../../redux/store'
import {filterByNameChanged, namePatternChanged, offsetChanged} from "../../features/files/list/fileListSlice";
import {useDebouncedCallback} from "use-debounce";
import DateTimeFormatter from "../../utils/formatters/DateTimeFormatter";
import {useNavigate, useMatch, Outlet} from "react-router-dom";
import Routes from "../../features/router/Routes";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FileService from "../../features/files/FileService";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import ErrorUtils from "../../utils/error/ErrorUtils";

function FileListItem(props) {
    const navigate = useNavigate()
    
    return (
        <>
            <ListItem secondaryAction={
                <>
                    <IconButton onClick={() => {
                        navigate('.' + Routes.Files.Edit.replace(':fileId', props.data.id))
                    }}>
                        <EditIcon/>
                    </IconButton>

                    <IconButton onClick={() => props.onDeleteClick(props.data)}>
                        <DeleteIcon/>
                    </IconButton>
                </>
            }>
                <ListItemText
                    primary={ props.data.name }
                    secondary={ (
                        <>
                            Rozmiar: { Math.round(props.data.size / (1024 * 1024) * 100) / 100 }  MB
                            <br/>
                            Data zapisu: { DateTimeFormatter.format(props.data.uploadedAt) }
                            <br/>
                            Data publikacji: { DateTimeFormatter.format(props.data.publicationFromDate) } - { DateTimeFormatter.format(props.data.publicationThruDate) }
                            <br/>
                            Data przypięcia: { DateTimeFormatter.format(props.data.pinFromDate) } - { DateTimeFormatter.format(props.data.pinThruDate) }
                        </>
                    )}>
                </ListItemText>
            </ListItem>

            <Divider/>
        </>
    )
}

export function Files() {
    const navigate = useNavigate()
    const data = useSelector((state) => state.fileList.data)
    const [currentItem, setCurrentItem] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const loadData = useDebouncedCallback(() => {
        FileListDataManager.load()
    }, 1000)
    
    useEffect(() => {
        setCurrentItem(null)
        store.dispatch(offsetChanged(0))
        FileListDataManager.load()
    }, [])
    
    if (!useMatch(Routes.Files.Index)) {
        return <Outlet/>
    }

    return (
        <>
            <StandardListView 
                title={"Dokumenty"} 
                initialize={() => {
                    return {
                        showSearchInput: store.getState().fileList.requestParams.filterByName,
                        searchPhrase: store.getState().fileList.requestParams.namePattern
                    }
                }}
                onSearchButtonClick={(showSearchInput) => {
                    store.dispatch(filterByNameChanged(showSearchInput))
                    loadData()
                }}
                onSearchPhraseChange={(e) => {
                    store.dispatch(namePatternChanged(e.target.value))
                    loadData()
                }}
                onAddButtonClick={() => {
                    navigate('.' + Routes.Files.Upload)
                }}
                loadData={() => loadData()}
                data={data}>
                <StandardListViewItems>
                    {
                        data.items.map((i) => (
                            <FileListItem key={i.id} data={i} onDeleteClick={(i) => {
                                setCurrentItem(i)
                                setOpenConfirmDialog(true)
                            }}/>
                        ))
                    }
                </StandardListViewItems>

                <ListConfig>
                    <FilterConfig>
                        <FileListFilterConfig/>
                    </FilterConfig>

                    <SortConfig>
                        <FileListSortConfig/>
                    </SortConfig>
                </ListConfig>
            </StandardListView>
            
            <Dialog open={openConfirmDialog}>
                <DialogTitle>Potwierdź usunięcie zasobu</DialogTitle>
                <DialogContent dividers>
                    Czy na pewno chcesz usunąc plik {currentItem?.name}?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setOpenConfirmDialog(false)
                    }}>
                        Anuluj
                    </Button>
                    
                    <Button onClick={() => {
                        setOpenConfirmDialog(false)
                        
                        FileService.deleteFile(currentItem.id)
                            .then(() => {
                                console.info("The file #" + currentItem.id + " has been deleted.")
                                store.dispatch(offsetChanged(0))
                                FileListDataManager.load()
                            })
                            .catch(err => {
                                console.error(err)
                                setShowError(true)
                                setErrorMessage(ErrorUtils.getMessage(err))
                            })
                    }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <FloatingAlert open={showError}
                           severity="error"
                           message={errorMessage}
                           viewHasActionButton={true}
                           onClose={() => setShowError(false)}/>
        </>
    )
}