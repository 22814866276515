import ListSubheader from "@mui/material/ListSubheader";
import React from "react";

export default function AvailableFilters({children}) {
    return (
        <>
            <ListSubheader className="available-filters-header">
                Dostępne filtry
            </ListSubheader>

            {children}
        </>
    )
}