import axios from "axios";

const PartyService = {
    createFindPartiesDefaultParams: function() {
        return {
            filterByPhrase: false,
            filterByPartyType: false,
            filterByNumberOfGardens: false,
            phrase: '',
            partyType: 'person',
            numberOfGardensFrom: 1,
            numberOfGardensTo: '',
            offset: 0,
            limit: 100,
            sortBy: 'name',
            sortDirection: 'asc'
        }
    },

    findParties: function (params) {
        return axios.get('/api/party', {
            params: {
                'filter-by-phrase': params.filterByPhrase,
                'filter-by-party-type': params.filterByPartyType,
                'filter-by-number-of-gardens': params.filterByNumberOfGardens,
                'phrase': params.phrase,
                'party-type': params.partyType,
                'number-of-gardens-from': params.numberOfGardensFrom,
                'number-of-gardens-to': params.numberOfGardensTo,
                'offset': params.offset,
                'limit': params.limit,
                'sort-by': params.sortBy,
                'sort-direction': params.sortDirection
            }
        });
    },

    getParty: function(partyId) {
        if (!partyId || typeof partyId != 'string') {
            throw new Error('The party id argument is not valid.')
        }

        return axios.get('/api/party/get/' + partyId)
    },

    getPartyDetails: function(partyId) {
        if (!partyId || typeof partyId != 'string') {
            throw new Error('The party id argument is not valid.')
        }

        return axios.get('/api/party/getDetails/' + partyId)
    },

    createParty: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/party/create', data)
    },

    updateParty: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/party/update', data)
    },

    deleteParty: function(partyId) {
        if (!partyId || typeof partyId != 'string') {
            throw new Error('The party id argument is not valid.')
        }

        return axios.delete('/api/party/delete/' + partyId)
    },
}

export default PartyService