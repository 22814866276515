import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function FloatingAlert({open, message, severity, onClose, viewHasActionButton}) {
    return (
        <>
            <Snackbar open={open} onClose={() => onClose()} autoHideDuration={3000} anchorOrigin={{ 
                horizontal: 'right', 
                vertical: 'bottom' 
            }} sx={{
                bottom: {
                    xs: viewHasActionButton ? 90 : 20
                }
            }}>
                <Alert severity={severity}>
                    { message }
                </Alert>
            </Snackbar>
        </>
    )
}