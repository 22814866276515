import List from "@mui/material/List";
import React from "react";
import AvailableFilters from "../../../components/list/filter/AvailableFilters";
import ActiveFilters from "../../../components/list/filter/ActiveFilters";
import Filter from "../../../components/list/filter/Filter";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { store } from "../../../redux/store"
import {useSelector} from "react-redux";
import {
    filterByStateChanged,
    filterByPinningChanged,
    isActiveChanged,
    isPinnedChanged
} from "./noticeListSlice";
import ListItem from "@mui/material/ListItem";
import NoticeListDataManager from './NoticeListDataManager'
import { useDebouncedCallback } from "use-debounce";
import Radio from "@mui/material/Radio";

export default function NoticeListFilterConfig () {
    const requestParams = useSelector((state) => state.noticeList.requestParams)
    const loadData = useDebouncedCallback(() => {
        NoticeListDataManager.load()
    }, 1000)

    if (!requestParams) {
        return <></>
    }

    return (
        <>
            <List>
                <AvailableFilters>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(filterByStateChanged(!requestParams.filterByState))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={requestParams.filterByState}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'filter-by-state'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="filter-by-state" primary="Stan"/>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(filterByPinningChanged(!requestParams.filterByPinning))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={requestParams.filterByPinning}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'filter-by-pinning'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="filter-by-pinning" primary="Przypięcie"/>
                        </ListItemButton>
                    </ListItem>
                </AvailableFilters>

                <ActiveFilters>
                    { requestParams.filterByState &&
                    <Filter name="Stan">
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(isActiveChanged(true))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.isActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'is-active'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="is-active" primary="Aktywny"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(isActiveChanged(false))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={!requestParams.isActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'is-inactive'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="is-inactive" primary="Nieaktywny"/>
                            </ListItemButton>
                        </ListItem>
                    </Filter>
                    }

                    { requestParams.filterByPinning &&
                    <Filter name="Przypięcie">
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(isPinnedChanged(true))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.isPinned}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'is-pinned'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="is-active" primary="Przypięty"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(isPinnedChanged(false))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={!requestParams.isPinned}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'is-not-pinned'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="is-not-pinned" primary="Nieprzypięty"/>
                            </ListItemButton>
                        </ListItem>
                    </Filter>
                    }
                </ActiveFilters>
            </List>
        </>
    )
}