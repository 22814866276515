import { createSlice } from '@reduxjs/toolkit'
import PartyService from "../PartyService";

const initialState = {
    data: {
        totalItems: 0,
        items: []
    },
    requestParams: PartyService.createFindPartiesDefaultParams()
}

export const partyListSlice = createSlice({
    name: 'partyList',
    initialState,
    reducers: {
        offsetChanged: (state, action) => {
            state.requestParams.offset = 0
        },
        filterByPhraseChanged: (state, action) => {
            state.requestParams.filterByPhrase = action.payload
            state.requestParams.offset = 0
        },
        filterByPartyTypeChanged: (state, action) => {
            state.requestParams.filterByPartyType = action.payload
            state.requestParams.offset = 0
        },
        filterByNumberOfGardensChanged: (state, action) => {
            state.requestParams.filterByNumberOfGardens = action.payload
            state.requestParams.offset = 0
        },
        phraseChanged: (state, action) => {
            state.requestParams.phrase = action.payload
            state.requestParams.offset = 0
        },
        partyTypeChanged: (state, action) => {
            state.requestParams.partyType = action.payload
            state.requestParams.offset = 0
        },
        numberOfGardensFromChanged: (state, action) => {
            state.requestParams.numberOfGardensFrom = action.payload
            state.requestParams.offset = 0
        },
        numberOfGardensToChanged: (state, action) => {
            state.requestParams.numberOfGardensTo = action.payload
            state.requestParams.offset = 0
        },
        sortByChanged: (state, action) => {
            state.requestParams.sortBy = action.payload
            state.requestParams.offset = 0
        },
        sortDirectionChanged: (state, action) => {
            state.requestParams.sortDirection = action.payload
            state.requestParams.offset = 0
        },
        dataChanged: (state, action) => {
            state.data = action.payload
            state.requestParams.offset = state.data.items.length
        }
    }
})

export const {
    offsetChanged,
    filterByPhraseChanged,
    filterByPartyTypeChanged,
    filterByNumberOfGardensChanged,
    phraseChanged,
    partyTypeChanged,
    numberOfGardensFromChanged,
    numberOfGardensToChanged,
    sortByChanged,
    sortDirectionChanged,
    dataChanged
} = partyListSlice.actions

export default partyListSlice.reducer