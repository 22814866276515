import dayjs from "dayjs"

const DateTimeFormatter = {
    format: function (date, defaultValue) {
        if (!date) {
            if (defaultValue) {
                return defaultValue
            }
            return '(brak)';
        }
                 
        return dayjs(date).format('YYYY.MM.DD HH:mm:ss')
    }
}

export default DateTimeFormatter