import {DefaultLayout} from "../../layouts/DefaultLayout";
import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MeterService from "../../features/meters/MeterService";
import InProgress from "../../components/progress/InProgress";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import {useParams} from "react-router";
import {store} from "../../redux/store";
import {offsetChanged} from "../../features/meters/list/meterListSlice";
import MeterListDataManager from "../../features/meters/list/MeterListDataManager";

export default function AddMeasurement() {
    const navigate = useNavigate()
    const [meterValue, setMeterValue] = useState('')
    const [minimumMeterValue, setMinimumMeterValue] = useState(0)
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()
    
    useEffect(() => {
        setInProgress(true)
        
        MeterService.getMeter(params.meterId)
            .then(r => {
                setInProgress(false)
                if (r.data.lastMeasurement && r.data.lastMeasurement.value) {
                    setMinimumMeterValue(r.data.lastMeasurement.value)
                }
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(e.response.data.message)
            })
    }, [params.meterId])

    function createFormData() {
        return {
            meterValue: {
                error: false,
                helperText: ''
            }
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true

        if (meterValue.trim().length === 0) {
            newFormData.meterValue.error = true
            newFormData.meterValue.helperText = 'Wartość nie może być pusta'
            isValid = false
        } else {
            let val = +(meterValue)

            if (isNaN(val) || !isFinite(val)) {
                newFormData.meterValue.error = true
                newFormData.meterValue.helperText = 'Należy wprowadzić poprawną liczbę'
                isValid = false
            } else if (val < 0) {
                newFormData.meterValue.error = true
                newFormData.meterValue.helperText = 'Wartość nie może być mniejsza od zera'
                isValid = false
            }
        }

        setFormData(newFormData)
        return isValid
    }

    function addMeasurement() {
        if (!validate()) {
            return
        }

        setInProgress(true)

        MeterService.addMeasurement({
            meterId: params.meterId,
            measurementValue: meterValue,
        }).then(() => {
            // TODO: dodac informacje o sukcesie
            setInProgress(false)
            store.dispatch(offsetChanged(0))
            MeterListDataManager.load()
            navigate('..')
        }).catch(err => {
            console.error(err)
            setInProgress(false)
            setShowError(true)
            setErrorMessage(err.response.data.message)
        })
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>
                            
                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Dodaj pomiar
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                
                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane pomiaru</h1>
                        
                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField label="Wartość licznika" fullWidth={true} 
                                               value={meterValue} 
                                               onChange={(e) => setMeterValue(e.target.value.replace(',', '.'))}
                                               error={formData.meterValue.error} 
                                               helperText={formData.meterValue.helperText}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        addMeasurement()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
                
                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}