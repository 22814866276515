const Routes = {
    Home: '/',
    Session: {
        Login: '/logowanie',
    },
    Gardens: {
        Index: '/dzialki',
        Details: '/szczegoly-dzialki/:gardenId',
    },
    News: {
        Index: '/aktualnosci',
        Edit: '/edytuj-wpis-aktualnosci/:newsId',
    },
    Notices: {
        Index: '/ogloszenia',
        Edit: '/edytuj-ogloszenie/:noticeId',
    },
    Content: {
        Item: {
            EditHtmlTextItem: '/edytuj-text-html/:contentItemId',
            AddImageItem: '/dodaj-obraz',
            EditImageItem: '/edytuj-dane-obrazu/:contentItemId',
        }
    },
    Files: {
        Index: '/dokumenty',
        Upload: '/przeslij-dokument',
        Edit: '/edytuj-dokument/:fileId',
    },
    PageVersions: {
        Index: '/strony/:pageId/wersje',
        Edit: '/edytuj-wersje-strony/:pageVersionId',
    },
    Parties: {
        Index: '/podmioty',
        Create: '/nowy-podmiot',
        Edit: '/edytuj-podmiot/:partyId',
        Details: '/szczegoly-podmiotu/:partyId',
    },
    Logins: {
        Create: '/nowy-login/:partyId',
        Edit: '/edytuj-login/:userLoginId',
        UpdatePassword: '/ustaw-haslo/:userLoginId',
    },
    Meters: {
        Index: '/liczniki',
        AddMeasurement: '/dodaj-pomiar/:meterId',
    },
}

export default Routes