import React from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

export function FloatingAddButton({ onClick }) {
    return (
        <>
            <Fab onClick={() => { onClick() }} color="primary" sx={{
                position: 'absolute',
                bottom: 16,
                right: 16
            }}>
                <AddIcon/>
            </Fab>
        </>
    )
}