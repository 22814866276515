import dayjs from "dayjs"

const SecurityRoleFormatter = {
    format: function (role) {
        switch (role) {
            case 'ROLE_USER':
                return 'Użytkownik'
            case 'ROLE_SUPER_USER':
                return 'Użytkownik zaawansowany'
            case 'ROLE_TREASURER':
                return 'Skarbnik'
            case 'ROLE_EMPLOYEE':
                return 'Pracownik'
            case 'ROLE_ADMIN':
                return 'Administrator'
            default:
                return '(nieznana rola)'
        }
        
        /*
        collections.SecurityRoles.InsertOne(new SecurityRole()
                    {
                        RoleName = ""
                    });
                    
                    collections.SecurityRoles.InsertOne(new SecurityRole()
                    {
                        RoleName = ""
                    });
                    
                    collections.SecurityRoles.InsertOne(new SecurityRole()
                    {
                        RoleName = ""
                    });
                    
                    collections.SecurityRoles.InsertOne(new SecurityRole()
                    {
                        RoleName = ""
                    });
                
                    collections.SecurityRoles.InsertOne(new SecurityRole()
                    {
                        RoleName = ""
                    });
         */
    }
}

export default SecurityRoleFormatter