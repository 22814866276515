import {DefaultLayout} from "../../layouts/DefaultLayout";
import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FileService from "../../features/files/FileService";
import dayjs from "dayjs";
import {DateTimePicker} from "@mui/x-date-pickers";
import InProgress from "../../components/progress/InProgress";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import {useParams} from "react-router";
import {store} from "../../redux/store";
import {offsetChanged} from "../../features/files/list/fileListSlice";
import FileListDataManager from "../../features/files/list/FileListDataManager";

export default function EditFile() {
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [publicationFromDate, setPublicationFromDate] = useState(null)
    const [publicationThruDate, setPublicationThruDate] = useState(null)
    const [pinFromDate, setPinFromDate] = useState(null)
    const [pinThruDate, setPinThruDate] = useState(null)
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()
    
    useEffect(() => {
        setInProgress(true)
        
        FileService.getFile(params.fileId)
            .then(r => {
                setInProgress(false)
                setName(r.data.name)
                
                if (r.data.publicationFromDate) {
                    setPublicationFromDate(dayjs(r.data.publicationFromDate))
                }
                if (r.data.publicationThruDate) {
                    setPublicationThruDate(dayjs(r.data.publicationThruDate))
                }
                
                if (r.data.pinFromDate) {
                    setPinFromDate(dayjs(r.data.pinFromDate))
                }
                if (r.data.pinThruDate) {
                    setPinThruDate(dayjs(r.data.pinThruDate))
                }
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(e.response.data.message)
            })
    }, [params.fileId])

    function createFormData() {
        return {
            name: {
                error: false,
                helperText: ''
            },
            publicationFromDate: {
                error: false,
                helperText: ''
            },
            publicationThruDate: {
                error: false,
                helperText: ''
            },
            pinFromDate: {
                error: false,
                helperText: ''
            },
            pinThruDate: {
                error: false,
                helperText: ''
            }
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true

        if (name.trim().length === 0) {
            newFormData.name.error = true
            newFormData.name.helperText = 'Nazwa nie może być pusta'
            isValid = false
        }

        if (publicationFromDate && !publicationFromDate.isValid()) {
            newFormData.publicationFromDate.error = true
            newFormData.publicationFromDate.helperText = 'Nieprawidłowa wartość'
            isValid = false
        }

        if (publicationThruDate) {
            if (!publicationThruDate.isValid()) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Nieprawidłowa wartość'
                isValid = false
            } else if (!publicationFromDate) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Należy najpierw podać początkową datę publikacji'
                isValid = false
            } else if (publicationFromDate && publicationFromDate.isValid() && publicationFromDate.isAfter(publicationThruDate)) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Data początkowa nie może być późniejsza niż data końcowa'
                isValid = false
            }
        }

        // TODO: nalezy usprawnic proces walidacji pin from / thru date tak aby nie trzeba bylo sie powtarzac
        if (pinFromDate && !pinFromDate.isValid()) {
            newFormData.pinFromDate.error = true
            newFormData.pinFromDate.helperText = 'Nieprawidłowa wartość'
            isValid = false
        }

        if (pinThruDate) {
            if (!pinThruDate.isValid()) {
                newFormData.pinThruDate.error = true
                newFormData.pinThruDate.helperText = 'Nieprawidłowa wartość'
                isValid = false
            } else if (!pinFromDate) {
                newFormData.pinThruDate.error = true
                newFormData.pinThruDate.helperText = 'Należy podać początkową datę przypięcia'
                isValid = false
            } else if (pinFromDate && pinFromDate.isValid() && pinFromDate.isAfter(pinThruDate)) {
                newFormData.pinThruDate.error = true
                newFormData.pinThruDate.helperText = 'Data początkowa nie może być późniejsza niż data końcowa'
                isValid = false
            }
        }

        setFormData(newFormData)
        return isValid
    }

    function updateFile() {
        if (!validate()) {
            return
        }

        setInProgress(true)

        FileService.updateFile({
            id: params.fileId,
            name: name,
            publicationFromDate: publicationFromDate,
            publicationThruDate: publicationThruDate,
            pinFromDate: pinFromDate,
            pinThruDate: pinThruDate
        }).then(() => {
            // TODO: dodac informacje o sukcesie
            setInProgress(false)
            store.dispatch(offsetChanged(0))
            FileListDataManager.load()
            navigate('..')
        }).catch(err => {
            console.error(err)
            setInProgress(false)
            setShowError(true)
            setErrorMessage(err.response.data.message)
        })
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Edycja dokumentu
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane dokumentu</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField label="Nazwa" fullWidth={true} 
                                               value={name} 
                                               onChange={(e) => setName(e.target.value)}
                                               error={formData.name.error} 
                                               helperText={formData.name.helperText}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data publikacji - od"
                                                    value={publicationFromDate}
                                                    onChange={(v) => setPublicationFromDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.publicationFromDate.error,
                                                            helperText: formData.publicationFromDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data publikacji - do"
                                                    value={publicationThruDate}
                                                    onChange={(v) => setPublicationThruDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.publicationThruDate.error,
                                                            helperText: formData.publicationThruDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data przypięcia - od"
                                                    value={pinFromDate}
                                                    onChange={(v) => setPinFromDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.pinFromDate.error,
                                                            helperText: formData.pinFromDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data przypięcia - do"
                                                    value={pinThruDate}
                                                    onChange={(v) => setPinThruDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.pinThruDate.error,
                                                            helperText: formData.pinThruDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        updateFile()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}