import React from "react";
import List from "@mui/material/List";
import {useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";
import GardenListDataManager from "./GardenListDataManager";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {store} from "../../../redux/store";
import { sortByChanged, sortDirectionChanged } from "./gardenListSlice";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import SortBy from "../../../components/list/sort/SortBy";
import SortDirection from "../../../components/list/sort/SortDirection";

export default function GardenListSortConfig () {
    const requestParams = useSelector((state) => state.gardenList.requestParams)
    const loadData = useDebouncedCallback(() => {
        GardenListDataManager.load()
    }, 1000)

    if (!requestParams) {
        return <></>
    }

    return (
        <>
            <List>
                <SortBy>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(sortByChanged('name'))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Radio
                                    edge="start"
                                    checked={requestParams.sortBy === 'name'}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'sort-by-name'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="sort-by-name" primary="Nazwy"/>
                        </ListItemButton>
                    </ListItem>
                </SortBy>

                <SortDirection>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(sortDirectionChanged('asc'))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Radio
                                    edge="start"
                                    checked={requestParams.sortDirection === 'asc'}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'sort-by-asc'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="sort-by-asc" primary="Rosnąco"/>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(sortDirectionChanged('desc'))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Radio
                                    edge="start"
                                    checked={requestParams.sortDirection === 'desc'}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'sort-by-desc'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="sort-by-desc" primary="Malejąco"/>
                        </ListItemButton>
                    </ListItem>
                </SortDirection>
            </List>
        </>
    )
}