import EditIcon from "@mui/icons-material/Edit";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import Routes from "../router/Routes";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";

function HtmlTextContentItem(props) {
    const navigate = useNavigate()
    
    return (
        <>
            <div className="content-item">
                <div className="content-item">
                    <div className="content-item-preview" dangerouslySetInnerHTML={{
                        __html: props.item.html
                    }}/>
                    <div className="content-item-actions">
                        <IconButton onClick={() => {
                            navigate('.' + Routes.Content.Item.EditHtmlTextItem.replace(':contentItemId', props.item.id))
                        }}>
                            <EditIcon/>
                        </IconButton>

                        <IconButton onClick={() => {
                            props.onMoveItemUp(props.item)
                        }}>
                            <ArrowUpwardIcon/>
                        </IconButton>

                        <IconButton onClick={() => {
                            props.onMoveItemDown(props.item)
                        }}>
                            <ArrowDownwardIcon/>
                        </IconButton>

                        <IconButton onClick={() => {
                            props.onDeleteItem(props.item)
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                </div>
            </div>
        </>
    )
}

function ImageContentItem(props) {
    const navigate = useNavigate()

    return (
        <>
            <div className="content-item">
                <div className="content-item">
                    <div className="content-item-preview" dangerouslySetInnerHTML={{
                        __html: props.item.html
                    }}/>
                    <div className="content-item-actions">
                        <IconButton onClick={() => {
                            navigate('.' + Routes.Content.Item.EditImageItem.replace(':contentItemId', props.item.id))
                        }}>
                            <EditIcon/>
                        </IconButton>

                        <IconButton onClick={() => {
                            props.onMoveItemUp(props.item)
                        }}>
                            <ArrowUpwardIcon/>
                        </IconButton>

                        <IconButton onClick={() => {
                            props.onMoveItemDown(props.item)
                        }}>
                            <ArrowDownwardIcon/>
                        </IconButton>

                        <IconButton onClick={() => {
                            props.onDeleteItem(props.item)
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default function ContentManager(props) {
    const [openMenuDialog, setOpenMenuDialog] = useState(false)
    const [currentItem, setCurrentItem] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    
    return (
        <>
            <div className="content-manager">
                <h2>Treść</h2>
                {
                    props.content && props.content.items.map((i) => (
                        <React.Fragment key={i.id}>
                            {
                                i.itemType === 'HtmlText' && 
                                <HtmlTextContentItem 
                                    item={i} 
                                    onMoveItemUp={(item) => {
                                        props.onMoveItemUp(item)
                                    }}
                                    onMoveItemDown={(item) => {
                                        props.onMoveItemDown(item)
                                    }}
                                    onDeleteItem={(item) => {
                                        setCurrentItem(item)
                                        setOpenConfirmDialog(true)
                                    }}/> 
                            }

                            {
                                i.itemType === 'Image' &&
                                <ImageContentItem
                                    item={i}
                                    onMoveItemUp={(item) => {
                                        props.onMoveItemUp(item)
                                    }}
                                    onMoveItemDown={(item) => {
                                        props.onMoveItemDown(item)
                                    }}
                                    onDeleteItem={(item) => {
                                        setCurrentItem(item)
                                        setOpenConfirmDialog(true)
                                    }}/>
                            }
                        </React.Fragment>
                    ))
                }

                {
                    (!props.content || props.content.items.length === 0) &&
                    <div className="no-content">
                        Brak treści
                    </div>
                }
                
                <Button variant="contained" size="large" onClick={() => {
                    setOpenMenuDialog(true)
                }}>
                    Dodaj
                </Button>
                
                <Dialog open={openMenuDialog} onClose={() => { setOpenMenuDialog(false) }}>
                    <DialogTitle>
                        Wybierz komponent
                    </DialogTitle>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemButton onClick={() => {
                                setOpenMenuDialog(false)
                                props.onAddHtmlTextItem()
                            }}>
                                <ListItemText>
                                    Tekst
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>

                        { props.onAddImageItem && 
                        <ListItem>
                            <ListItemButton onClick={() => {
                                setOpenMenuDialog(false)
                                props.onAddImageItem()
                            }}>
                                <ListItemText>
                                    Obraz
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        }
                    </List>
                </Dialog>

                <Dialog open={openConfirmDialog}>
                    <DialogTitle>Potwierdź usunięcie zasobu</DialogTitle>
                    <DialogContent dividers>
                        Czy na pewno chcesz usunąc komponent?
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => {
                            setOpenConfirmDialog(false)
                        }}>
                            Anuluj
                        </Button>

                        <Button onClick={() => {
                            setOpenConfirmDialog(false)
                            props.onDeleteItem(currentItem)
                        }}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}