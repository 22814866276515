import { createSlice } from '@reduxjs/toolkit'
import NewsService from "../NewsService";

const initialState = {
    data: {
        totalItems: 0,
        items: []
    },
    requestParams: NewsService.createFindNewsDefaultParams()
}

export const newsListSlice = createSlice({
    name: 'newsList',
    initialState,
    reducers: {
        offsetChanged: (state, action) => {
            state.requestParams.offset = 0
        },
        filterByPhraseChanged: (state, action) => {
            state.requestParams.filterByPhrase = action.payload
            state.requestParams.offset = 0
        },
        filterByStateChanged: (state, action) => {
            state.requestParams.filterByState = action.payload
            state.requestParams.offset = 0
        },
        phraseChanged: (state, action) => {
            state.requestParams.phrase = action.payload
            state.requestParams.offset = 0
        },
        isActiveChanged: (state, action) => {
            state.requestParams.isActive = action.payload
            state.requestParams.offset = 0
        },
        sortByChanged: (state, action) => {
            state.requestParams.sortBy = action.payload
            state.requestParams.offset = 0
        },
        sortDirectionChanged: (state, action) => {
            state.requestParams.sortDirection = action.payload
            state.requestParams.offset = 0
        },
        dataChanged: (state, action) => {
            state.data = action.payload
            state.requestParams.offset = state.data.items.length
        }
    }
})

export const {
    offsetChanged,
    filterByPhraseChanged,
    filterByStateChanged,
    phraseChanged,
    isActiveChanged,
    sortByChanged,
    sortDirectionChanged,
    dataChanged
} = newsListSlice.actions

export default newsListSlice.reducer