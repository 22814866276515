import axios from "axios";

const FileService = {
    createFindFilesDefaultParams: function() {
        return {
            filterByName: false,
            filterByState: false,
            filterByPinning: false,
            namePattern: '',
            isActive: true,
            isPinned: true,
            offset: 0,
            limit: 100,
            sortBy: 'upload-date',
            sortDirection: 'desc'
        }
    },
    
    findFiles: function (params) {
        return axios.get('/api/file', {
            params: {
                'filter-by-name': params.filterByName,
                'filter-by-state': params.filterByState,
                'filter-by-pinning': params.filterByPinning,
                'name-pattern': params.namePattern,
                'is-active': params.isActive,
                'is-pinned': params.isPinned,
                'offset': params.offset,
                'limit': params.limit,
                'sort-by': params.sortBy,
                'sort-direction': params.sortDirection
            }
        });
    },

    getFile: function(fileId) {
        if (!fileId || typeof fileId != 'string') {
            throw new Error('The file id argument is not valid.')
        }

        return axios.get('/api/file/get/' + fileId)
    },

    updateFile: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/file/update', data)
    },
    
    uploadFile: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }
        
        let formData = new FormData()
        formData.append('name', data.name)
        formData.append('file', data.file)
        
        if (data.publicationFromDate) {
            formData.append('publicationFromDate', data.publicationFromDate.utc().format())
        }

        if (data.publicationThruDate) {
            formData.append('publicationThruDate', data.publicationThruDate.utc().format())
        }

        if (data.pinFromDate) {
            formData.append('pinFromDate', data.pinFromDate.utc().format())
        }

        if (data.pinThruDate) {
            formData.append('pinThruDate', data.pinThruDate.utc().format())
        }

        return axios.post('/api/file/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    deleteFile: function(fileId) {
        if (!fileId || typeof fileId != 'string') {
            throw new Error('The file id argument is not valid.')
        }

        return axios.delete('/api/file/delete/' + fileId)
    },
}

export default FileService