const PasswordGenerator = {
    generate: function(length) {
        if (length < 1) {
            throw new Error('Password length should be greater or equal to 1.')
        }

        let chars = []

        for (let ch = 'A'.charCodeAt(0); ch <= 'Z'.charCodeAt(0); ch++) {
            if (ch === 'O'.charCodeAt(0) || ch === 'I'.charCodeAt(0)) {
                continue
            }
            
            chars.push(String.fromCharCode(ch))
        }

        for (let ch = 'a'.charCodeAt(0); ch <= 'z'.charCodeAt(0); ch++) {
            if (ch === 'l'.charCodeAt(0)) {
                continue
            }
            chars.push(String.fromCharCode(ch))
        }

        for (let ch = '1'.charCodeAt(0); ch <= '9'.charCodeAt(0); ch++) {
            chars.push(String.fromCharCode(ch))
        }
        
        let password = ''
        
        for (let i = 0; i < length; i++) {
            let index = Math.floor(Math.random() * chars.length)
            
            if (index < 0) {
                index = 0
            }

            if (index > chars.length - 1) {
                index = chars.length - 1
            }
        
            password += chars[index]
        }
        
        return password
    },
}

export default PasswordGenerator