import React, {useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useSelector} from "react-redux";
import ListConfig, {FilterConfig, SortConfig} from "../../components/list/ListConfig";
import MeterListFilterConfig from "../../features/meters/list/MeterListFilterConfig";
import MeterListSortConfig from "../../features/meters/list/MeterListSortConfig";
import MeterListDataManager from "../../features/meters/list/MeterListDataManager";
import StandardListView, {StandardListViewItems} from "../../components/list/StandardListView";
import {store} from '../../redux/store'
import {filterByPhraseChanged, phraseChanged, offsetChanged} from "../../features/meters/list/meterListSlice";
import {useDebouncedCallback} from "use-debounce";
import DateTimeFormatter from "../../utils/formatters/DateTimeFormatter";
import {useNavigate, useMatch, Outlet} from "react-router-dom";
import Routes from "../../features/router/Routes";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import MeterService from "../../features/meters/MeterService";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import ErrorUtils from "../../utils/error/ErrorUtils";

function MeterListItem(props) {
    const navigate = useNavigate()
    
    function meterValue(data) {
        if (!data.lastMeasurement) {
            return 0
        }

        if (!data.lastMeasurement.value) {
            return '(brak danych)'
        }
        
        return data.lastMeasurement.value
    }

    function lastMeasuredAt(data) {
        if (!data.lastMeasurement) {
            return '(brak danych)'
        }
        return DateTimeFormatter.format(data.lastMeasurement.measuredAt)
    }

    function lastMeasuredBy(data) {
        if (!data.lastMeasurement || !data.lastMeasurement.measuredBy) {
            return '(brak danych)'
        }
        
        let name = ''

        if (data.lastMeasurement.measuredBy.lastName && data.lastMeasurement.measuredBy.lastName.trim() !== '') {
            name += data.lastMeasurement.measuredBy.lastName.trim()
        }

        if (data.lastMeasurement.measuredBy.firstName && data.lastMeasurement.measuredBy.firstName.trim() !== '') {
            name += ' ' + data.lastMeasurement.measuredBy.firstName.trim()
        }
        
        return name.trim()
    }
    
    return (
        <>
            <ListItem secondaryAction={
                <>
                    <IconButton onClick={() => {
                        navigate('.' + Routes.Meters.AddMeasurement.replace(':meterId', props.data.id))
                    }}>
                        <EditIcon/>
                    </IconButton>
                </>
            }>
                <ListItemText
                    primary={ props.data.active ? props.data.name : props.data.name + ' (nieaktywny)' }
                    secondary={ (
                        <>
                            Wartość: { meterValue(props.data) }
                            <br/>
                            Ostatni pomiar: { lastMeasuredAt(props.data) }
                            <br/>
                            Wykonawca pomiaru: { lastMeasuredBy(props.data) }
                        </>
                    )}>
                </ListItemText>
            </ListItem>

            <Divider/>
        </>
    )
}

export function Meters() {
    const navigate = useNavigate()
    const data = useSelector((state) => state.meterList.data)
    const [currentItem, setCurrentItem] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const loadData = useDebouncedCallback(() => {
        MeterListDataManager.load()
    }, 1000)
    
    useEffect(() => {
        setCurrentItem(null)
        store.dispatch(offsetChanged(0))
        MeterListDataManager.load()
    }, [])
    
    if (!useMatch(Routes.Meters.Index)) {
        return <Outlet/>
    }

    return (
        <>
            <StandardListView 
                title={"Liczniki"} 
                initialize={() => {
                    return {
                        showSearchInput: store.getState().meterList.requestParams.filterByPhrase,
                        searchPhrase: store.getState().meterList.requestParams.phrase
                    }
                }}
                onSearchButtonClick={(showSearchInput) => {
                    store.dispatch(filterByPhraseChanged(showSearchInput))
                    loadData()
                }}
                onSearchPhraseChange={(e) => {
                    store.dispatch(phraseChanged(e.target.value))
                    loadData()
                }}
                /*
                onAddButtonClick={() => {
                    navigate('.' + Routes.Meters.Upload)
                }}
                 */
                loadData={() => loadData()}
                data={data}>
                <StandardListViewItems>
                    {
                        data.items.map((i) => (
                            <MeterListItem key={i.id} data={i} onDeleteClick={(i) => {
                                setCurrentItem(i)
                                setOpenConfirmDialog(true)
                            }}/>
                        ))
                    }
                </StandardListViewItems>

                <ListConfig>
                    <FilterConfig>
                        <MeterListFilterConfig/>
                    </FilterConfig>

                    <SortConfig>
                        <MeterListSortConfig/>
                    </SortConfig>
                </ListConfig>
            </StandardListView>
            
            <Dialog open={openConfirmDialog}>
                <DialogTitle>Potwierdź usunięcie zasobu</DialogTitle>
                <DialogContent dividers>
                    Czy na pewno chcesz usunąc licznik {currentItem?.name}?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setOpenConfirmDialog(false)
                    }}>
                        Anuluj
                    </Button>
                    
                    <Button onClick={() => {
                        setOpenConfirmDialog(false)
                        
                        /*MeterService.deleteMeter(currentItem.id)
                            .then(() => {
                                console.info("The meter #" + currentItem.id + " has been deleted.")
                                store.dispatch(offsetChanged(0))
                                MeterListDataManager.load()
                            })
                            .catch(err => {
                                console.error(err)
                                setShowError(true)
                                setErrorMessage(ErrorUtils.getMessage(err))
                            })*/
                    }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <FloatingAlert open={showError}
                           severity="error"
                           message={errorMessage}
                           viewHasActionButton={false}
                           onClose={() => setShowError(false)}/>
        </>
    )
}