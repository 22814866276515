
const ErrorUtils = {
    getMessage: function(e) {
        if (e && e.response && e.response.data && e.response.data.message) {
            return e.response.data.message
        }
        
        return "Wystąpił błąd"
    }
}

export default ErrorUtils