import { store } from '../../../redux/store'
import NoticeService from "../NoticeService";
import { dataChanged } from './noticeListSlice'
import ArrayUtils from "../../../utils/ArrayUtils";
import RequestQueue from "../../../utils/net/RequestQueue";

let isInitialized = false
let queue;

if (!isInitialized) {
    isInitialized = true
    queue = new RequestQueue()
    queue.subscribe({
        success: function (r) {
            const state = store.getState()

            if (state.noticeList.requestParams.offset > 0) {
                r.data.items = ArrayUtils.merge(state.noticeList.data.items, r.data.items, (i) => i.id)
            }

            store.dispatch(dataChanged(r.data))
        },
        error: function (r) {
            // TODO: rozwazyc powiadomienie uzytkownika o bledzie.
            console.error(r)
        },
        complete: function (r) {
            console.debug('Stream of notice list request has been completed.')
        }
    })
}

const NoticeListDataManager = {
    load: function() {
        const state = store.getState()
        queue.addRequest(NoticeService.findNotices(state.noticeList.requestParams))
    }
}

export default NoticeListDataManager