import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {DefaultLayout} from "../../../layouts/DefaultLayout";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";
import InProgress from "../../../components/progress/InProgress";
import FloatingAlert from "../../../components/notifications/FloatingAlert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {UploadFile as UploadFileInput} from "../../../components/controls/Inputs";
import Button from "@mui/material/Button";

export function AddImageItem(props) {
    const navigate = useNavigate()
    const altRef = useRef(null)
    const fileRef = useRef(null)

    function createFormData() {
        return {
            file: {
                error: false,
                helperText: ''
            }
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true

        if (fileRef.current.files.length === 0) {
            newFormData.file.error = true
            newFormData.file.helperText = 'Nie wskazano pliku'
            isValid = false
        }

        setFormData(newFormData)
        return isValid
    }

    function uploadFile() {
        if (!validate()) {
            return
        }

        if (props.onUploadFile) {
            props.onUploadFile({
                alt: altRef.current.value,
                file: fileRef.current.files[0]
            })
        }
    }
    
    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Dodawanie obrazu
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane obrazu</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField label="Tekst alternatywny" fullWidth={true} inputRef={altRef}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <UploadFileInput inputRef={fileRef}
                                                     error={formData.file.error}
                                                     helperText={formData.file.helperText}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        uploadFile()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>

                <InProgress open={props.inProgress}/>
                
                <FloatingAlert open={props.showError}
                               severity="error"
                               message={props.errorMessage}
                               onClose={() => props.onErrorAlertClose()}/>
            </DefaultLayout>
        </>
    )
}

export function EditImageItem(props) {
    const navigate = useNavigate()
    const altRef = useRef(null)
    const params = useParams()
    const [isInitialized, setInitialized] = useState(false);
    
    useEffect(() => {
        if (props.onInit && !isInitialized) {
            setInitialized(true)
            props.onInit({
                altRef: altRef
            })
        }
    }, [props, isInitialized])

    function updateItem() {
        if (props.onUpdateItem) {
            props.onUpdateItem({
                contentItemId: params.contentItemId,
                alt: altRef.current.value
            })
        }
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Edycja danych obrazu
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane obrazu</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField label="Tekst alternatywny" placeholder="Wprowadź tekst alternatywny" 
                                               fullWidth={true} inputRef={altRef} 
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        updateItem()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>

                <InProgress open={props.inProgress}/>

                <FloatingAlert open={props.showError}
                               severity="error"
                               message={props.errorMessage}
                               onClose={() => props.onErrorAlertClose()}/>
            </DefaultLayout>
        </>
    )
}