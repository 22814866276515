import axios from "axios";

const LoginService = {
    createLogin: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/login/create', data)
    },

    getLogin: function(loginId) {
        if (!loginId || typeof loginId != 'string') {
            throw new Error('The login id argument is not valid.')
        }

        return axios.get('/api/login/get/' + loginId)
    },

    updateLogin: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/login/update', data)
    },

    updatePassword: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/login/updatePassword', data)
    },

    deleteLogin: function(loginId) {
        if (!loginId || typeof loginId != 'string') {
            throw new Error('The login id argument is not valid.')
        }

        return axios.delete('/api/login/delete/' + loginId)
    },
}

export default LoginService