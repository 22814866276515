import React, {useState} from "react";
import {useNavigate, useParams} from "react-router";
import PageService from "../../../../../features/pages/PageService";
import ErrorUtils from "../../../../../utils/error/ErrorUtils";
import {EditImageItem} from "../../../../../features/content/item/ImageItem";

export default function EditImageItemInPageVersionPage() {
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
    const params = useParams()
    
    return <EditImageItem 
        inProgress={inProgress}
        showError={showError} 
        errorMessage={errorMessage}
        onInit={(data) => {
            setInProgress(true)
            PageService.getContentItem(
                params.pageId,
                params.pageVersionId, 
                params.contentItemId
            ).then(r => {
                // TODO: dodac informacje o sukcesie
                setInProgress(false)
                data.altRef.current.value = r.data.alt
            }).catch(err => {
                console.error(err)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(err))
            })
        }}
        onUpdateItem={(data) => {
            setInProgress(true)
            data.pageId = params.pageId
            data.pageVersionId = params.pageVersionId
            PageService.updateImageContentItem(data)
            .then(() => {
                // TODO: dodac informacje o sukcesie
                setInProgress(false)
                navigate('..')
            }).catch(err => {
                console.error(err)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(err))
            })
        }}
        onErrorAlertClose={() => {
            setShowError(false)
        }}/>
}