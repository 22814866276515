import axios from "axios";

const PageService = {
    getPage: function(pageId) {
        if (!pageId || typeof pageId != 'string') {
            throw new Error('The page id argument is not valid.')
        }

        return axios.get('/api/page/' + pageId)
    },
    
    createFindPageVersionsDefaultParams: function() {
        return {
            filterByState: false,
            isActive: true,
            offset: 0,
            limit: 100,
            sortBy: 'created-date',
            sortDirection: 'desc'
        }
    },

    findPageVersions: function (pageId, params) {
        return axios.get('/api/page/' + pageId + '/versions', {
            params: {
                'filter-by-state': params.filterByState,
                'is-active': params.isActive,
                'offset': params.offset,
                'limit': params.limit,
                'sort-by': params.sortBy,
                'sort-direction': params.sortDirection
            }
        });
    },

    createPageVersion: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/page/version/create', data)
    },

    clonePageVersion: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/page/version/clone', data)
    },

    getPageVersion: function(pageId, pageVersionId) {
        if (!pageId || typeof pageId != 'string') {
            throw new Error('The page id argument is not valid.')
        }

        if (!pageVersionId || typeof pageVersionId != 'string') {
            throw new Error('The page version id argument is not valid.')
        }

        return axios.get('/api/page/' + pageId + '/version/' + pageVersionId)
    },

    updatePageVersion: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/page/version/update', data)
    },

    deletePageVersion: function(pageId, pageVersionId) {
        if (!pageId || typeof pageId != 'string') {
            throw new Error('The page id argument is not valid.')
        }

        if (!pageVersionId || typeof pageVersionId != 'string') {
            throw new Error('The page version id argument is not valid.')
        }

        return axios.delete('/api/page/' + pageId + '/version/' + pageVersionId + '/delete')
    },

    getContentItem: function(pageId, pageVersionId, contentItemId) {
        if (!pageId || typeof pageId != 'string') {
            throw new Error('The page id argument is not valid.')
        }

        if (!pageVersionId || typeof pageVersionId != 'string') {
            throw new Error('The page version id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.get('/api/page/' + pageId + '/version/' + pageVersionId + '/content/item/' + contentItemId)
    },

    moveContentItemUp: function(pageId, pageVersionId, contentItemId) {
        if (!pageId || typeof pageId != 'string') {
            throw new Error('The page id argument is not valid.')
        }

        if (!pageVersionId || typeof pageVersionId != 'string') {
            throw new Error('The page version id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.put('/api/page/' + pageId + '/version/' + pageVersionId + '/content/item/' + contentItemId + '/move-up')
    },

    moveContentItemDown: function(pageId, pageVersionId, contentItemId) {
        if (!pageId || typeof pageId != 'string') {
            throw new Error('The page id argument is not valid.')
        }

        if (!pageVersionId || typeof pageVersionId != 'string') {
            throw new Error('The page version id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.put('/api/page/' + pageId + '/version/' + pageVersionId + '/content/item/' + contentItemId + '/move-down')
    },

    deleteContentItem: function(pageId, pageVersionId, contentItemId) {
        if (!pageId || typeof pageId != 'string') {
            throw new Error('The page id argument is not valid.')
        }

        if (!pageVersionId || typeof pageVersionId != 'string') {
            throw new Error('The page version id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }
        
        return axios.delete('/api/page/' + pageId + '/version/' + pageVersionId + '/content/item/' + contentItemId)
    },

    addHtmlTextContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/page/version/content/item/add-html-text', data)
    },

    updateHtmlTextContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/page/version/content/item/update-html-text', data)
    },

    addImageContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        let formData = new FormData()
        formData.append('pageId', data.pageId)
        formData.append('pageVersionId', data.pageVersionId)
        formData.append('alt', data.alt)
        formData.append('file', data.file)

        return axios.post('/api/page/version/content/item/add-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    updateImageContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/page/version/content/item/update-image', data)
    },
}

export default PageService