import List from "@mui/material/List";
import React from "react";
import AvailableFilters from "../../../components/list/filter/AvailableFilters";
import ActiveFilters from "../../../components/list/filter/ActiveFilters";
import Filter from "../../../components/list/filter/Filter";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { store } from "../../../redux/store"
import {useSelector} from "react-redux";
import {
    filterByStateChanged,
    filterByTypeChanged,
    filterByLastMeasurementTimeChanged,
    isActiveChanged,
    typeChanged,
    lastMeasurementTimeFromChanged,
    lastMeasurementTimeThruChanged
} from "./meterListSlice";
import ListItem from "@mui/material/ListItem";
import MeterListDataManager from './MeterListDataManager'
import { useDebouncedCallback } from "use-debounce";
import Radio from "@mui/material/Radio";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function MeterListFilterConfig () {
    const requestParams = useSelector((state) => state.meterList.requestParams)
    const loadData = useDebouncedCallback(() => {
        MeterListDataManager.load()
    }, 1000)
    
    if (!requestParams) {
        return <></>
    }
    
    return (
        <>
            <List>
                <AvailableFilters>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(filterByStateChanged(!requestParams.filterByState))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={requestParams.filterByState}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'filter-by-state'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="filter-by-state" primary="Stan"/>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(filterByTypeChanged(!requestParams.filterByType))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={requestParams.filterByType}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'filter-by-type'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="filter-by-type" primary="Typ licznika"/>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(filterByLastMeasurementTimeChanged(!requestParams.filterByLastMeasurementTime))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={requestParams.filterByLastMeasurementTime}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'filter-by-last-measurement-time'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="filter-by-last-measurement-time" primary="Czas ostatniego pomiaru"/>
                        </ListItemButton>
                    </ListItem>
                </AvailableFilters>

                <ActiveFilters>
                    { requestParams.filterByState &&
                    <Filter name="Stan">
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(isActiveChanged(true))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.isActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'is-active'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="is-active" primary="Aktywny"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(isActiveChanged(false))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={!requestParams.isActive}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'is-inactive'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="is-inactive" primary="Nieaktywny"/>
                            </ListItemButton>
                        </ListItem>
                    </Filter>
                    }

                    { requestParams.filterByType &&
                    <Filter name="Typ licznika">
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(typeChanged('WaterMeter'))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.type === 'WaterMeter'}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'water-meter'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="water-meter" primary="Licznik wody"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(typeChanged('ElectricityMeter'))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.type === 'ElectricityMeter'}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'electricity-meter'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="electricity-meter" primary="Licznik prądu"/>
                            </ListItemButton>
                        </ListItem>
                    </Filter>
                    }

                    { requestParams.filterByLastMeasurementTime &&
                    <Filter name="Czas ostatniego pomiaru">
                        <ListItem>
                            <DateTimePicker label="Ostatni pomiar - od"
                                            value={
                                                requestParams.lastMeasurementTimeFrom !== null && requestParams.lastMeasurementTimeFrom !== ''
                                                ? dayjs(requestParams.lastMeasurementTimeFrom) : null
                                            }
                                            slotProps={{
                                                actionBar: { 
                                                    actions: ['today', 'accept', 'clear'] 
                                                }
                                            }}
                                            onChange={(v) => { 
                                                store.dispatch(lastMeasurementTimeFromChanged(v !== null && v !== '' 
                                                    ? v.utc().format() : null))
                                                loadData()
                                            }}/>
                        </ListItem>

                        <ListItem>
                            <DateTimePicker label="Ostatni pomiar - do"
                                            value={
                                                requestParams.lastMeasurementTimeThru !== null && requestParams.lastMeasurementTimeThru !== ''
                                                    ? dayjs(requestParams.lastMeasurementTimeThru) : null
                                            }
                                            slotProps={{
                                                actionBar: {
                                                    actions: ['today', 'accept', 'clear']
                                                }
                                            }}
                                            onChange={(v) => {
                                                store.dispatch(lastMeasurementTimeThruChanged(v !== null && v !== ''
                                                    ? v.utc().format() : null))
                                                loadData()
                                            }}/>
                        </ListItem>
                    </Filter>
                    }
                </ActiveFilters>
            </List>
        </>
    )
}