import {DefaultLayout} from "../../layouts/DefaultLayout";
import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import InProgress from "../../components/progress/InProgress";
import {useParams} from "react-router";
import GardenService from "../../features/gardens/GardenService";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Routes from "../../features/router/Routes";
import ErrorUtils from "../../utils/error/ErrorUtils";

export default function GardenDetailsPage() {
    const navigate = useNavigate()

    const [gardenDetails, setGardenDetails] = useState(null)

    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const params = useParams()
    const locationMatch = useMatch(Routes.Gardens.Index + Routes.Gardens.Details);
    
    function updateData() {
        setInProgress(true)
        
        GardenService.getGardenDetails(params.gardenId)
            .then(r => {
                setInProgress(false)
                setGardenDetails(r.data)
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(e))
            })
    }

    useEffect(() => {
        if (!locationMatch) {
            return
        }
        
        updateData()
    }, [locationMatch])

    if (!locationMatch) {
        return <Outlet/>
    }
    
    function formatArea(data) {
        if (data === null)
            return '(brak danych)'
        
        return <>
            { data } m<sup>2</sup>
        </>
    }
    
    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Szczegóły działki
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        { gardenDetails &&
                            <>
                                <section>
                                    <Box sx={{ borderRadius: 2, bgcolor: 'background.paper'}}>
                                        <List>
                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="sectionHeader">Dane działki</Typography>}/>
                                            </ListItem>

                                            <Divider/>

                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">Nazwa</Typography>}
                                                    secondary={<Typography variant="value">{ gardenDetails.name ?? '(Brak danych)' }</Typography>}/>
                                            </ListItem>

                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">Sektor</Typography>}
                                                    secondary={<Typography variant="value">{ gardenDetails.sector ?? '(Brak danych)' }</Typography>}/>
                                            </ListItem>

                                            <ListItem disableGutters>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography variant="label">Powierzchnia</Typography>}
                                                    secondary={<Typography variant="value">{ formatArea(gardenDetails.area) }</Typography>}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </section>
                            </>
                        }
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}
