import {DefaultLayout} from "../layouts/DefaultLayout";
import React, {useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import {MainMenu} from "../components/menu/MainMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import CommonButtons from "../components/app-bar/CommonButtons";

export function HomePage() {
    const [isMainMenuOpened, setMainMenuOpened] = useState(false)
    const theme = useTheme()
    const atMostMdScreen = useMediaQuery(theme.breakpoints.down('md'))
    
    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            { atMostMdScreen &&
                            <IconButton size="large" color="inherit" edge="start" aria-label="menu" sx={{mr: 1}}
                                        onClick={() => setMainMenuOpened(!isMainMenuOpened)}>
                                <MenuIcon/>
                            </IconButton>
                            }
                            <Typography variant="h1" sx={{ flexGrow: 1 }}>Strona główna</Typography>
                            <CommonButtons/>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <p>Strona w budowie!</p>
                    </div>
                </div>
                
                <Drawer id="drawer-main-menu" class="{drawer}" open={atMostMdScreen && isMainMenuOpened} 
                        onClose={() => setMainMenuOpened(!isMainMenuOpened)}>
                    <MainMenu/>
                </Drawer>
            </DefaultLayout>
        </>
    )
}