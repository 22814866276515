import axios from "axios";

const NoticeService = {
    createFindNoticesDefaultParams: function() {
        return {
            filterByPhrase: false,
            filterByState: false,
            filterByPinning: false,
            phrase: '',
            isActive: true,
            isPinned: true,
            offset: 0,
            limit: 100,
            sortBy: 'created-date',
            sortDirection: 'desc'
        }
    },

    findNotices: function (params) {
        return axios.get('/api/notice', {
            params: {
                'filter-by-phrase': params.filterByPhrase,
                'filter-by-state': params.filterByState,
                'filter-by-pinning': params.filterByPinning,
                'phrase': params.phrase,
                'is-active': params.isActive,
                'is-pinned': params.isPinned,
                'offset': params.offset,
                'limit': params.limit,
                'sort-by': params.sortBy,
                'sort-direction': params.sortDirection
            }
        });
    },

    createNotice: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/notice/create', data)
    },

    getNotice: function(noticeId) {
        if (!noticeId || typeof noticeId != 'string') {
            throw new Error('The notice id argument is not valid.')
        }

        return axios.get('/api/notice/get/' + noticeId)
    },

    updateNotice: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/notice/update', data)
    },

    deleteNotice: function(noticeId) {
        if (!noticeId || typeof noticeId != 'string') {
            throw new Error('The notice id argument is not valid.')
        }

        return axios.delete('/api/notice/delete/' + noticeId)
    },

    getContentItem: function(noticeId, contentItemId) {
        if (!noticeId || typeof noticeId != 'string') {
            throw new Error('The notice id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.get('/api/notice/' + noticeId + '/content/item/' + contentItemId)
    },

    moveContentItemUp: function(noticeId, contentItemId) {
        if (!noticeId || typeof noticeId != 'string') {
            throw new Error('The notice id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.put('/api/notice/' + noticeId + '/content/item/' + contentItemId + '/move-up')
    },

    moveContentItemDown: function(noticeId, contentItemId) {
        if (!noticeId || typeof noticeId != 'string') {
            throw new Error('The notice id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }

        return axios.put('/api/notice/' + noticeId + '/content/item/' + contentItemId + '/move-down')
    },

    deleteContentItem: function(noticeId, contentItemId) {
        if (!noticeId || typeof noticeId != 'string') {
            throw new Error('The notice id argument is not valid.')
        }

        if (!contentItemId || typeof contentItemId != 'string') {
            throw new Error('The content item id argument is not valid.')
        }
        
        return axios.delete('/api/notice/' + noticeId + '/content/item/' + contentItemId)
    },

    addHtmlTextContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/notice/content/item/add-html-text', data)
    },

    updateHtmlTextContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/notice/content/item/update-html-text', data)
    },

    addImageContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        let formData = new FormData()
        formData.append('noticeId', data.noticeId)
        formData.append('alt', data.alt)
        formData.append('file', data.file)

        return axios.post('/api/notice/content/item/add-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    updateImageContentItem: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/notice/content/item/update-image', data)
    },
}

export default NoticeService