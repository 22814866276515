// TODO: rozwazyc inne opcje rozwiazania problemu wyscigu
// Ponadto zastanowic sie nad tym czy mozna subskrybowac ciagle
// strumien RxJS

import {Observable, of, switchAll, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";

class RequestQueue {
    #requests = []
    #requestProvider = null
    
    constructor() {
        this.#requestProvider = new Observable((s) => {
            setInterval(() => {
                if (this.#requests.length > 0) {
                    s.next(this.#requests.shift())
                }
            })
        })
    }
    
    subscribe(config) {
        this.#requestProvider
            .pipe(switchMap(v => {
                return of(v)
                    .pipe(switchAll(), catchError(e => of(e)))
            }))
            .subscribe({
                next: (r) => {
                    if (r instanceof Error) {
                        if (config.error) {
                            config.error(r)
                        }
                        return
                    }
                    
                    if (config.success) {
                        config.success(r)
                    }
                },
                error: (err) => {
                    if (config.error) {
                        config.error(err)
                    }
                },
                complete: () => {
                    if (config.complete) {
                        config.complete()
                    }
                }
            })
    }

    addRequest(request) {
        this.#requests.push(request)
    }
}

export default RequestQueue