import { configureStore } from '@reduxjs/toolkit'
import sessionReducer from '../features/session/sessionSlice'
import gardenListReducer from '../features/gardens/list/gardenListSlice'
import fileListReducer from '../features/files/list/fileListSlice'
import newsListReducer from '../features/news/list/newsListSlice'
import noticeListReducer from '../features/notices/list/noticeListSlice'
import pageVersionListReducer from '../features/pages/list/pageVersionListSlice'
import partyListReducer from '../features/parties/list/partyListSlice'
import meterListReducer from '../features/meters/list/meterListSlice'

export const store = configureStore({
    reducer: {
        session: sessionReducer,
        gardenList: gardenListReducer,
        fileList: fileListReducer,
        newsList: newsListReducer,
        noticeList: noticeListReducer,
        pageVersionList: pageVersionListReducer,
        partyList: partyListReducer,
        meterList: meterListReducer,
    }
})
