import List from "@mui/material/List";
import React from "react";
import AvailableFilters from "../../../components/list/filter/AvailableFilters";
import ActiveFilters from "../../../components/list/filter/ActiveFilters";
import Filter from "../../../components/list/filter/Filter";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { store } from "../../../redux/store"
import {useSelector} from "react-redux";
import {
    filterBySectorChanged,
    sectorChanged
} from "./gardenListSlice";
import ListItem from "@mui/material/ListItem";
import GardenListDataManager from './GardenListDataManager'
import { useDebouncedCallback } from "use-debounce";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import {filterByPartyTypeChanged, partyTypeChanged} from "../../parties/list/partyListSlice";

export default function GardenListFilterConfig () {
    const requestParams = useSelector((state) => state.gardenList.requestParams)
    const loadData = useDebouncedCallback(() => {
        GardenListDataManager.load()
    }, 1000)
    
    if (!requestParams) {
        return <></>
    }
    
    return (
        <>
            <List>
                <AvailableFilters>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            store.dispatch(filterBySectorChanged(!requestParams.filterBySector))
                            loadData()
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={requestParams.filterBySector}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': 'filter-by-sector'
                                    }}/>
                            </ListItemIcon>

                            <ListItemText id="filter-by-sector" primary="Sektor"/>
                        </ListItemButton>
                    </ListItem>
                </AvailableFilters>

                <ActiveFilters>
                    { requestParams.filterBySector &&
                    <Filter name="Sektor">
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(sectorChanged('all'))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.sector === 'all'}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'all-sectors'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="all-sectors" primary="Wszystkie"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(sectorChanged('Sektor 1'))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.sector === 'Sektor 1'}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'sector-1'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="sector-1" primary="Sektor 1"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(sectorChanged('Sektor 2'))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.sector === 'Sektor 2'}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'sector-2'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="sector-2" primary="Sektor 2"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                store.dispatch(sectorChanged('Sektor 3'))
                                loadData()
                            }}>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={requestParams.sector === 'Sektor 3'}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': 'sector-3'
                                        }}/>
                                </ListItemIcon>

                                <ListItemText id="sector-3" primary="Sektor 3"/>
                            </ListItemButton>
                        </ListItem>
                    </Filter>
                    }
                </ActiveFilters>
            </List>
        </>
    )
}