import {DefaultLayout} from "../../../layouts/DefaultLayout";
import React, {useEffect, useRef, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import InProgress from "../../../components/progress/InProgress";
import FloatingAlert from "../../../components/notifications/FloatingAlert";
import {useParams} from "react-router";
import LoginService from "../../../features/security/LoginService";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

export default function EditLoginPage() {
    const navigate = useNavigate()
    const params = useParams()

    // Login
    const [role, setRole] = useState('')
    const [enabled, setEnabled] = useState(false)

    // Others
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        setInProgress(true)

        LoginService.getLogin(params.userLoginId)
            .then(r => {
                setInProgress(false)
                if (r.data.roles && r.data.roles.length > 0) {
                    setRole(r.data.roles[0])
                }
                setEnabled(r.data.enabled)
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(e.response.data.message)
            })
    }, [params.userLoginId])

    function createFormData() {
        return {
            role: {
                error: false,
                helperText: ''
            },
            password: {
                error: false,
                helperText: ''
            },
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true

        if (!role) {
            newFormData.role.error = true
            newFormData.role.helperText = 'Należy podać rolę.'
            isValid = false
        }

        setFormData(newFormData)
        return isValid
    }

    function updateLogin() {
        if (!validate()) {
            return
        }

        setInProgress(true)

        LoginService.updateLogin({
            // Login
            loginId: params.userLoginId,
            role: role,
            enabled: enabled,
        }).then(() => {
            // TODO: dodac informacje o sukcesie
            setInProgress(false)
            navigate('..')
        }).catch(err => {
            console.error(err)
            setInProgress(false)
            setShowError(true)
            setErrorMessage(err.response.data.message)
        })
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Edycja loginu
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane loginu</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true} error={formData.role.error}>
                                        <InputLabel id="role-label" shrink={true}>Rola</InputLabel>
                                        <Select labelId="role-label"
                                                label="Rola"
                                                value={role} 
                                                onChange={(e) => setRole(e.target.value)}>
                                            <MenuItem value="ROLE_USER">Użytkownik</MenuItem>
                                            <MenuItem value="ROLE_EMPLOYEE">Pracownik</MenuItem>
                                            <MenuItem value="ROLE_TREASURER">Skarbnik</MenuItem>
                                            <MenuItem value="ROLE_SUPER_USER">Użytkownik zaawansowany</MenuItem>
                                        </Select>
                                        <FormHelperText>{formData.role.helperText}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={enabled} onChange={() => setEnabled(!enabled)}/>
                                            }
                                            label="Aktywny"/>
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        updateLogin()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}