import React, {useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useSelector} from "react-redux";
import ListConfig, {FilterConfig, SortConfig} from "../../../components/list/ListConfig";
import PageVersionListFilterConfig from "../../../features/pages/list/PageVersionListFilterConfig";
import PageVersionListSortConfig from "../../../features/pages/list/PageVersionListSortConfig";
import PageVersionListDataManager from "../../../features/pages/list/PageVersionListDataManager";
import StandardListView, {StandardListViewItems} from "../../../components/list/StandardListView";
import {store} from '../../../redux/store'
import {offsetChanged} from "../../../features/pages/list/pageVersionListSlice";
import {useDebouncedCallback} from "use-debounce";
import DateTimeFormatter from "../../../utils/formatters/DateTimeFormatter";
import {useNavigate, useMatch, Outlet} from "react-router-dom";
import Routes from "../../../features/router/Routes";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import PageService from "../../../features/pages/PageService";
import FloatingAlert from "../../../components/notifications/FloatingAlert";
import ErrorUtils from "../../../utils/error/ErrorUtils";
import {useParams} from "react-router";

function PageVersionListItem(props) {
    const navigate = useNavigate()

    return (
        <>
            <ListItem secondaryAction={
                <>
                    <IconButton onClick={() => props.onCloneClick(props.data)}>
                        <ContentCopyIcon/>
                    </IconButton>
                    
                    <IconButton onClick={() => {
                        navigate('.' + Routes.PageVersions.Edit.replace(':pageVersionId', props.data.id))
                    }}>
                        <EditIcon/>
                    </IconButton>

                    <IconButton onClick={() => props.onDeleteClick(props.data)}>
                        <DeleteIcon/>
                    </IconButton>
                </>
            }>
                <ListItemText
                    primary={ 'Wersja z dnia ' + DateTimeFormatter.format(props.data.createdAt) }
                    secondary={ (
                        <>
                            Data publikacji: { DateTimeFormatter.format(props.data.publicationFromDate) } - { DateTimeFormatter.format(props.data.publicationThruDate) }
                        </>
                    )}>
                </ListItemText>
            </ListItem>

            <Divider/>
        </>
    )
}

export function PageVersionsPage() {
    const navigate = useNavigate()
    const data = useSelector((state) => state.pageVersionList.data)
    const [pageName, setPageName] = useState('')
    const [currentItem, setCurrentItem] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()
    const loadData = useDebouncedCallback(() => {
        PageVersionListDataManager.load(params.pageId)
    }, 1000)

    useEffect(() => {
        PageService.getPage(params.pageId)
            .then(r => {
                setPageName(r.data.name)
            })
            .catch(err => {
                console.error(err)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(err))
            });
        
        setCurrentItem(null)
        store.dispatch(offsetChanged(0))
        PageVersionListDataManager.load(params.pageId)
    }, [params.pageId])

    if (!useMatch(Routes.PageVersions.Index)) {
        return <Outlet/>
    }

    return (
        <>
            <StandardListView
                title={ pageName }
                initialize={() => {
                    return {
                        showSearchInput: false,
                        searchPhrase: ''
                    }
                }}
                hideSearchButton={true}
                onAddButtonClick={() => {
                    PageService.createPageVersion({
                        pageId: params.pageId
                    }).then((r) => {
                        console.info("The page version #" + r.data.id + " has been created.")
                        store.dispatch(offsetChanged(0))
                        PageVersionListDataManager.load(params.pageId)
                        navigate('.' + Routes.PageVersions.Edit.replace(':pageVersionId', r.data.id))
                    }).catch(err => {
                        console.error(err)
                        setShowError(true)
                        setErrorMessage(ErrorUtils.getMessage(err))
                    })
                }}
                loadData={() => loadData()}
                data={data}>
                <StandardListViewItems>
                    {
                        data.items.map((i) => (
                            <PageVersionListItem key={i.id} data={i} onDeleteClick={(i) => {
                                setCurrentItem(i)
                                setOpenConfirmDialog(true)
                            }} onCloneClick={() => {
                                PageService.clonePageVersion({
                                    pageId: params.pageId,
                                    pageVersionId: i.id
                                }).then(() => {
                                    store.dispatch(offsetChanged(0))
                                    PageVersionListDataManager.load(params.pageId)
                                }).catch(err => {
                                    console.error(err)
                                    setShowError(true)
                                    setErrorMessage(ErrorUtils.getMessage(err))
                                })
                            }}/>
                        ))
                    }
                </StandardListViewItems>

                <ListConfig>
                    <FilterConfig>
                        <PageVersionListFilterConfig/>
                    </FilterConfig>

                    <SortConfig>
                        <PageVersionListSortConfig/>
                    </SortConfig>
                </ListConfig>
            </StandardListView>

            <Dialog open={openConfirmDialog}>
                <DialogTitle>Potwierdź usunięcie zasobu</DialogTitle>
                <DialogContent dividers>
                    Czy na pewno chcesz usunąc wpis {currentItem?.title}?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setOpenConfirmDialog(false)
                    }}>
                        Anuluj
                    </Button>

                    <Button onClick={() => {
                        setOpenConfirmDialog(false)

                        PageService.deletePageVersion(params.pageId, currentItem.id)
                            .then(() => {
                                console.info("The page version #" + currentItem.id + " has been deleted.")
                                store.dispatch(offsetChanged(0))
                                PageVersionListDataManager.load(params.pageId)
                            })
                            .catch(err => {
                                console.error(err)
                                setShowError(true)
                                setErrorMessage(ErrorUtils.getMessage(err))
                            })
                    }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <FloatingAlert open={showError}
                           severity="error"
                           message={errorMessage}
                           viewHasActionButton={true}
                           onClose={() => setShowError(false)}/>
        </>
    )
}