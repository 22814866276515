import axios from "axios";

const MeterService = {
    createFindMetersDefaultParams: function() {
        return {
            filterByPhrase: false,
            filterByState: true,
            filterByType: false,
            filterByLastMeasurementTime: false,
            phrase: '',
            isActive: true,
            type: 'WaterMeter',
            lastMeasurementTimeFrom: null,
            lastMeasurementTimeThru: null,
            offset: 0,
            limit: 100,
            sortBy: 'name',
            sortDirection: 'asc'
        }
    },
    
    findMeters: function (params) {
        return axios.get('/api/meter', {
            params: {
                'filter-by-phrase': params.filterByPhrase,
                'filter-by-state': params.filterByState,
                'filter-by-type': params.filterByType,
                'filter-by-last-measurement-time': params.filterByLastMeasurementTime,
                'phrase': params.phrase,
                'is-active': params.isActive,
                'type': params.type,
                'last-measurement-time-from': params.lastMeasurementTimeFrom,
                'last-measurement-time-thru': params.lastMeasurementTimeThru,
                'offset': params.offset,
                'limit': params.limit,
                'sort-by': params.sortBy,
                'sort-direction': params.sortDirection
            }
        });
    },

    getMeter: function(meterId) {
        if (!meterId || typeof meterId != 'string') {
            throw new Error('The meter id argument is not valid.')
        }

        return axios.get('/api/meter/get/' + meterId)
    },

    addMeasurement: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.post('/api/meter/add-measurement', data)
    },
}

export default MeterService