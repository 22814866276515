import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";

export function FilterConfig({children}) {
    return (
        <>
            { children }
        </>
    )
}
FilterConfig.displayName = 'FilterConfig'

export function SortConfig({children}) {
    return (
        <>
            { children }
        </>
    )
}
SortConfig.displayName = 'SortConfig'

export function ViewConfig({children}) {
    return (
        <>
            { children }
        </>
    )
}
ViewConfig.displayName = 'ViewConfig'

export default function ListConfig({children}) {
    const [currentTab, setCurrentTab] = useState(0)
    const [panels, setPanels] = useState([])
    
    function tabChanged(event, newCurrentTab) {
        setCurrentTab(newCurrentTab)
    }
    
    useEffect(() => {
        let filterConfig
        let sortConfig
        let viewConfig

        React.Children.map(children, (ch) => {
            if (ch.type.displayName === 'FilterConfig') {
                filterConfig = ch
            }

            if (ch.type.displayName === 'SortConfig') {
                sortConfig = ch
            }

            if (ch.type.displayName === 'ViewConfig') {
                viewConfig = ch
            }
        })

        let panelArray = []

        if (filterConfig) {
            panelArray.push({
                name: 'Filtry',
                component: filterConfig
            })
        }

        if (sortConfig) {
            panelArray.push({
                name: 'Sortowanie',
                component: sortConfig
            })
        }

        if (viewConfig) {
            panelArray.push({
                name: 'Opcje',
                component: viewConfig
            })
        }
        
        setPanels(panelArray)
    }, [children])
    
    if (panels.length === 0) {
        return <></>
    }
    
    return (
        <>
            <Box display="flex" sx={{
                flexDirection: 'column'
            }}>
                <Box sx={{ paddingBottom: 2 }}>
                    <Tabs value={currentTab} onChange={tabChanged}>
                        { panels.map((p) => (
                            <Tab key={p.name} label={p.name}/>
                        ))}
                    </Tabs>
                </Box>
                <Box sx={{ flexGrow: 1, overflow: 'scroll' }}>
                    <Box>
                        { panels[currentTab].component }
                    </Box>
                </Box>
            </Box>
        </>
    )
}
ListConfig.displayName = 'ListConfig'