import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import SecurityRoleFormatter from "../../../utils/formatters/SecurityRoleFormatter";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Routes from "../../router/Routes";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import LoginService from "../../security/LoginService";

export default function PartyLogins (props) {
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    
    function formatLoginDetails(login) {
        let roles = ''
        login.securityRoles.map((r, i) => {
            roles += SecurityRoleFormatter.format(r).toLowerCase()
            if (i !== login.securityRoles.length - 1) {
                roles += ', '
            }
        })
        
        return (
            <>
                Aktywny: { login.enabled === true ? 'tak' : 'nie' }
                <br/>
                Rola: { roles }
            </>
        )
    }
    
    return (
        <>
            <section>
                <Box sx={{ borderRadius: 2, bgcolor: 'background.paper'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <List>
                                <ListItem disableGutters>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={<Typography variant="sectionHeader">Loginy</Typography>}/>
                                </ListItem>

                                <Divider/>

                                {
                                    props.logins.map((l, i) => (
                                        <React.Fragment key={i}>
                                            <ListItem disableGutters secondaryAction={
                                                <>
                                                    <IconButton onClick={() => {
                                                        navigate('.' + Routes.Logins.UpdatePassword.replace(':userLoginId', l.userLoginId))
                                                    }}>
                                                        <KeyIcon/>
                                                    </IconButton>

                                                    <IconButton onClick={() => {
                                                        navigate('.' + Routes.Logins.Edit.replace(':userLoginId', l.userLoginId))
                                                    }}>
                                                        <EditIcon/>
                                                    </IconButton>

                                                    <IconButton onClick={() => {
                                                        setCurrentItem(l)
                                                        setOpenConfirmDialog(true)
                                                    }}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </>
                                            }>
                                                <ListItemText
                                                    primary={'Nazwa użytkownika: ' + l.userLoginId}
                                                    secondary={formatLoginDetails(l)}/>
                                            </ListItem>
                                            <Divider/>
                                        </React.Fragment>
                                    ))
                                }
                            </List>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={(e) => {
                                navigate('.' + Routes.Logins.Create.replace(':partyId', props.partyId))
                                e.preventDefault()
                            }}>
                                Utwórz
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <Dialog open={openConfirmDialog}>
                <DialogTitle>Potwierdź usunięcie zasobu</DialogTitle>
                <DialogContent dividers>
                    Czy na pewno chcesz usunąc login {currentItem?.userLoginId}?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setOpenConfirmDialog(false)
                    }}>
                        Anuluj
                    </Button>

                    <Button onClick={() => {
                        setOpenConfirmDialog(false)
                        
                        if (props.inProgress) {
                            props.inProgress()
                        }

                        LoginService.deleteLogin(currentItem.userLoginId)
                            .then(() => {
                                console.info("The login #" + currentItem.userLoginId + " has been deleted.")
                                
                                if (props.onLoginDeleted) {
                                    props.onLoginDeleted(currentItem)
                                }
                            })
                            .catch(err => {
                                console.error(err)
                                if (props.onError) {
                                    props.onError(err)
                                }
                            })
                    }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}