import ListSubheader from "@mui/material/ListSubheader";
import React from "react";

export default function FilterHeader({name, children}) {
    return (
        <>
            <ListSubheader className="filter-header">
                { name }
            </ListSubheader>

            { children }
        </>
    )
}