import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isSessionInitialized: false,
    isUserAuthenticated: false,
    isPrivilegedUser: false,
    highestSecurityRole: '',
    sessionInfo: {}
}

function findHighestSecurityRole(sessionInfo) {
    if (sessionInfo.securityRoles.includes('ROLE_ADMIN')) {
        return 'ROLE_ADMIN'
    }

    if (sessionInfo.securityRoles.includes('ROLE_SUPER_USER')) {
        return 'ROLE_SUPER_USER'
    }

    if (sessionInfo.securityRoles.includes('ROLE_TREASURER')) {
        return 'ROLE_TREASURER'
    }

    if (sessionInfo.securityRoles.includes('ROLE_EMPLOYEE')) {
        return 'ROLE_EMPLOYEE'
    }

    if (sessionInfo.securityRoles.includes('ROLE_USER')) {
        return 'ROLE_USER'
    }
    
    return ''
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        sessionInitialized: (state, action) => {
            state.isSessionInitialized = true
            state.isUserAuthenticated = action.payload.isUserAuthenticated
        },
        userLoggedIn: (state) => {
            state.isUserAuthenticated = true
        },
        userLoggedOut: (state) => {
            state.isUserAuthenticated = false
        },
        sessionInfoChanged: (state, action) => {
            state.sessionInfo = action.payload
            state.highestSecurityRole = findHighestSecurityRole(state.sessionInfo)
            state.isPrivilegedUser = state.highestSecurityRole.startsWith('ROLE') 
                && state.highestSecurityRole !== 'ROLE_USER'
        },
    }
})

export const { sessionInitialized, userLoggedIn, userLoggedOut, sessionInfoChanged } = sessionSlice.actions
export default sessionSlice.reducer