import { store } from '../../redux/store'
import {sessionInfoChanged, sessionInitialized, userLoggedIn, userLoggedOut} from './sessionSlice'
import axios from "axios";
import Routes from "../router/Routes";


const SessionService = {
    login: function(username, password) {
        let requestData = 'username=' + username
            + '&password=' + password

        return axios
            .post('/api/session/login', requestData)
            .then(() => {
                store.dispatch(userLoggedIn())

                axios.get('/api/session/info')
                    .then(r => {
                        store.dispatch(sessionInfoChanged(r.data))
                    })
                    .catch(e => {
                        console.error(e)
                    })
            })
    },
    
    logout: function () {
        return axios.post('/api/session/logout')
            .then(() => {
                store.dispatch(userLoggedOut());
            })
    }
}

export default SessionService