import { useLocation, useNavigate } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {sessionInfoChanged, sessionInitialized} from './sessionSlice'
import {useEffect} from "react";
import axios from "axios";
import Routes from "../router/Routes";

let isComponentInitialized = false

export function SessionMonitor() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const session =  useSelector((state) => {
        return state.session
    })
    
    useEffect(() => {
        if (!isComponentInitialized) {
            isComponentInitialized = true
            axios.get('/api/session/info')
                .then(r => {
                    dispatch(sessionInfoChanged(r.data))
                    
                    if (r.data.isUserAuthenticated
                        && location.pathname === Routes.Session.Login) {
                        navigate(Routes.Home)
                        dispatch(sessionInitialized(r.data))
                        return
                    }

                    if (!r.data.isUserAuthenticated
                        && location.pathname !== Routes.Session.Login) {
                        navigate(Routes.Session.Login)
                        dispatch(sessionInitialized(r.data))
                        return
                    }

                    dispatch(sessionInitialized(r.data))
                })
                .catch(e => {
                    console.error(e)
                })
            
            return;
        }
        
        if (!session.isSessionInitialized) {
            return;
        }

        if (session.isUserAuthenticated
            && location.pathname === Routes.Session.Login) {
            navigate(Routes.Home)
            return
        }

        if (!session.isUserAuthenticated
            && location.pathname !== Routes.Session.Login) {
            navigate(Routes.Session.Login)
        }
    })
    
    return (
        <></>
    )
}