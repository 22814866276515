import {DefaultLayout} from "../../layouts/DefaultLayout";
import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import NoticeService from "../../features/notices/NoticeService";
import dayjs from "dayjs";
import {DateTimePicker} from "@mui/x-date-pickers";
import InProgress from "../../components/progress/InProgress";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import {useParams} from "react-router";
import {store} from "../../redux/store";
import {offsetChanged} from "../../features/notices/list/noticeListSlice";
import NoticeListDataManager from "../../features/notices/list/NoticeListDataManager";
import ErrorUtils from "../../utils/error/ErrorUtils";
import ContentManager from "../../features/content/ContentManager";
import Routes from "../../features/router/Routes";

export default function EditNoticePage() {
    const navigate = useNavigate()
    // TODO: mozna rozwazyc optymalizacje tak aby widok nie byl odswiezany za kazdym razem kiedy sie zmieni title
    const [title, setTitle] = useState('')
    const [publicationFromDate, setPublicationFromDate] = useState(null)
    const [publicationThruDate, setPublicationThruDate] = useState(null)
    const [pinFromDate, setPinFromDate] = useState(null)
    const [pinThruDate, setPinThruDate] = useState(null)
    const [content, setContent] = useState(null)
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()
    const locationMatch = useMatch(Routes.Notices.Index + Routes.Notices.Edit);

    useEffect(() => {
        if (!locationMatch) {
            return
        }
        setInProgress(true)

        NoticeService.getNotice(params.noticeId)
            .then(r => {
                setInProgress(false)
                setTitle(r.data.title)
                if (r.data.publicationFromDate) {
                    setPublicationFromDate(dayjs(r.data.publicationFromDate))
                }
                if (r.data.publicationThruDate) {
                    setPublicationThruDate(dayjs(r.data.publicationThruDate))
                }
                if (r.data.pinFromDate) {
                    setPinFromDate(dayjs(r.data.pinFromDate))
                }
                if (r.data.pinThruDate) {
                    setPinThruDate(dayjs(r.data.pinThruDate))
                }
                setContent(r.data.content)
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(e))
            })
    }, [params.noticeId, locationMatch])

    function createFormData() {
        return {
            title: {
                error: false,
                helperText: ''
            },
            publicationFromDate: {
                error: false,
                helperText: ''
            },
            publicationThruDate: {
                error: false,
                helperText: ''
            },
            pinFromDate: {
                error: false,
                helperText: ''
            },
            pinThruDate: {
                error: false,
                helperText: ''
            }
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true

        if (title.trim().length === 0) {
            newFormData.title.error = true
            newFormData.title.helperText = 'Tytuł nie może być pusty'
            isValid = false
        }
        
        // TODO: nalezy usprawnic proces walidacji publication from / thru date tak aby nie trzeba bylo sie powtarzac
        if (publicationFromDate && !publicationFromDate.isValid()) {
            newFormData.publicationFromDate.error = true
            newFormData.publicationFromDate.helperText = 'Nieprawidłowa wartość'
            isValid = false
        }

        if (publicationThruDate) {
            if (!publicationThruDate.isValid()) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Nieprawidłowa wartość'
                isValid = false
            } else if (!publicationFromDate) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Należy najpierw podać początkową datę publikacji'
                isValid = false
            } else if (publicationFromDate && publicationFromDate.isValid() && publicationFromDate.isAfter(publicationThruDate)) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Data początkowa nie może być późniejsza niż data końcowa'
                isValid = false
            }
        }

        // TODO: nalezy usprawnic proces walidacji pin from / thru date tak aby nie trzeba bylo sie powtarzac
        if (pinFromDate && !pinFromDate.isValid()) {
            newFormData.pinFromDate.error = true
            newFormData.pinFromDate.helperText = 'Nieprawidłowa wartość'
            isValid = false
        }

        if (pinThruDate) {
            if (!pinThruDate.isValid()) {
                newFormData.pinThruDate.error = true
                newFormData.pinThruDate.helperText = 'Nieprawidłowa wartość'
                isValid = false
            } else if (!pinFromDate) {
                newFormData.pinThruDate.error = true
                newFormData.pinThruDate.helperText = 'Należy podać początkową datę przypięcia'
                isValid = false
            } else if (pinFromDate && pinFromDate.isValid() && pinFromDate.isAfter(pinThruDate)) {
                newFormData.pinThruDate.error = true
                newFormData.pinThruDate.helperText = 'Data początkowa nie może być późniejsza niż data końcowa'
                isValid = false
            }
        }

        setFormData(newFormData)
        return isValid
    }

    function updateNotice() {
        if (!validate()) {
            return
        }

        setInProgress(true)

        NoticeService.updateNotice({
            id: params.noticeId,
            title: title,
            publicationFromDate: publicationFromDate,
            publicationThruDate: publicationThruDate,
            pinFromDate: pinFromDate,
            pinThruDate: pinThruDate
        }).then(() => {
            // TODO: dodac informacje o sukcesie
            setInProgress(false)
            store.dispatch(offsetChanged(0))
            NoticeListDataManager.load()
            navigate('..')
        }).catch(err => {
            console.error(err)
            setInProgress(false)
            setShowError(true)
            setErrorMessage(ErrorUtils.getMessage(err))
        })
    }

    if (!locationMatch) {
        return <Outlet/>
    }
    
    function refreshContent() {
        setInProgress(true)
        NoticeService.getNotice(params.noticeId)
            .then(r => {
                setInProgress(false)
                setContent(r.data.content)
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(e))
            })
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Edycja ogłoszenia
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane ogłoszenia</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField label="Tytuł" fullWidth={true}
                                               value={title}
                                               onChange={(e) => setTitle(e.target.value)}
                                               error={formData.title.error}
                                               helperText={formData.title.helperText}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data publikacji - od"
                                                    value={publicationFromDate}
                                                    onChange={(v) => setPublicationFromDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.publicationFromDate.error,
                                                            helperText: formData.publicationFromDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data publikacji - do"
                                                    value={publicationThruDate}
                                                    onChange={(v) => setPublicationThruDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.publicationThruDate.error,
                                                            helperText: formData.publicationThruDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data przypięcia - od"
                                                    value={pinFromDate}
                                                    onChange={(v) => setPinFromDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.pinFromDate.error,
                                                            helperText: formData.pinFromDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data przypięcia - do"
                                                    value={pinThruDate}
                                                    onChange={(v) => setPinThruDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.pinThruDate.error,
                                                            helperText: formData.pinThruDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        updateNotice()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        
                        <ContentManager 
                            content={content} 
                            onAddHtmlTextItem={() => {
                                setInProgress(true)
                                NoticeService.addHtmlTextContentItem({
                                    noticeId: params.noticeId, 
                                    html: '<p>Wprowadź treść</p>'
                                }).then((r) => {
                                    setInProgress(false)
                                    navigate('.' + Routes.Content.Item.EditHtmlTextItem.replace(':contentItemId', r.data.id))
                                }).catch((e) => {
                                    setInProgress(false)
                                    setShowError(true)
                                    setErrorMessage(ErrorUtils.getMessage(e))
                                })
                            }}
                            onAddImageItem={() => {
                                navigate('.' + Routes.Content.Item.AddImageItem)
                            }}
                            onMoveItemUp={(item) => {
                                setInProgress(true)
                                NoticeService.moveContentItemUp(params.noticeId, item.id)
                                    .then(() => {
                                        setInProgress(false)
                                        refreshContent()
                                    })
                                    .catch((e) => {
                                        setInProgress(false)
                                        setShowError(true)
                                        setErrorMessage(ErrorUtils.getMessage(e))
                                    })
                            }}
                            onMoveItemDown={(item) => {
                                setInProgress(true)
                                NoticeService.moveContentItemDown(params.noticeId, item.id)
                                    .then(() => {
                                        setInProgress(false)
                                        refreshContent()
                                    })
                                    .catch((e) => {
                                        setInProgress(false)
                                        setShowError(true)
                                        setErrorMessage(ErrorUtils.getMessage(e))
                                    })
                            }}
                            onDeleteItem={(item) => {
                                NoticeService.deleteContentItem(params.noticeId, item.id)
                                    .then(r => {
                                        setInProgress(false)
                                        refreshContent()
                                    })
                                    .catch(e => {
                                        setInProgress(false)
                                        setShowError(true)
                                        setErrorMessage(ErrorUtils.getMessage(e))
                                        refreshContent()
                                    })
                            }}/>
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}