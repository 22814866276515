import { Outlet } from 'react-router-dom'
import { SessionMonitor } from "./features/session/SessionMonitor";
import './App.css';
import React from "react";
import { useState } from "react"
import { store } from "./redux/store"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { plPL } from "@mui/x-date-pickers/locales";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import "dayjs/locale/pl"

dayjs.extend(utc)

const theme = createTheme({
    typography: {
        sectionHeader: {
            color: 'rgb(0, 0, 0)',
            fontSize: '2rem',
            fontFamily: 'Roboto, "Helvetica", Arial, sans-serif',
            display: 'block'
        },
        label: {
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.875rem',
            fontFamily: 'Roboto, "Helvetica", Arial, sans-serif',
            display: 'block'
        },
        value: {
            color: 'rgb(0, 0, 0)',
            fontSize: '1rem',
            fontFamily: 'Roboto, "Helvetica", Arial, sans-serif',
            display: 'block',
            lineHeight: '1.5'
        }
    }
}, plPL)

function App() {
    const [isSessionInitialized, setSessionInitialized] = useState(false)
    
    store.subscribe(() => {
        const state = store.getState()
        if (state.session.isSessionInitialized) {
            setSessionInitialized(true)
        }
    })
    
    if (!isSessionInitialized) {
        return <SessionMonitor/>
    }
    
    return (
        <>
            <SessionMonitor/>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                    <Outlet/>
                </LocalizationProvider>
            </ThemeProvider>
        </>
    );
}

export default App;
