import ListSubheader from "@mui/material/ListSubheader";
import React from "react";

export default function SortDirection({children}) {
    return (
        <>
            <ListSubheader className="sort-direction-header">
                Kierunek sortowania
            </ListSubheader>

            {children}
        </>
    )
}