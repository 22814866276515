import {DefaultLayout} from "../../../layouts/DefaultLayout";
import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PageService from "../../../features/pages/PageService";
import dayjs from "dayjs";
import {DateTimePicker} from "@mui/x-date-pickers";
import InProgress from "../../../components/progress/InProgress";
import FloatingAlert from "../../../components/notifications/FloatingAlert";
import {useParams} from "react-router";
import {store} from "../../../redux/store";
import {offsetChanged} from "../../../features/pages/list/pageVersionListSlice";
import PageVersionListDataManager from "../../../features/pages/list/PageVersionListDataManager";
import ErrorUtils from "../../../utils/error/ErrorUtils";
import ContentManager from "../../../features/content/ContentManager";
import Routes from "../../../features/router/Routes";

export default function EditPageVersionPage() {
    const navigate = useNavigate()
    const [publicationFromDate, setPublicationFromDate] = useState(null)
    const [publicationThruDate, setPublicationThruDate] = useState(null)
    const [content, setContent] = useState(null)
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()
    const locationMatch = useMatch(Routes.PageVersions.Index + Routes.PageVersions.Edit);

    useEffect(() => {
        if (!locationMatch) {
            return
        }
        setInProgress(true)

        PageService.getPageVersion(params.pageId, params.pageVersionId)
            .then(r => {
                setInProgress(false)
                if (r.data.publicationFromDate) {
                    setPublicationFromDate(dayjs(r.data.publicationFromDate))
                }
                if (r.data.publicationThruDate) {
                    setPublicationThruDate(dayjs(r.data.publicationThruDate))
                }
                setContent(r.data.content)
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(e))
            })
    }, [params.pageVersionId, locationMatch])

    function createFormData() {
        return {
            publicationFromDate: {
                error: false,
                helperText: ''
            },
            publicationThruDate: {
                error: false,
                helperText: ''
            },
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true
        
        // TODO: nalezy usprawnic proces walidacji publication from / thru date tak aby nie trzeba bylo sie powtarzac
        if (publicationFromDate && !publicationFromDate.isValid()) {
            newFormData.publicationFromDate.error = true
            newFormData.publicationFromDate.helperText = 'Nieprawidłowa wartość'
            isValid = false
        }

        if (publicationThruDate) {
            if (!publicationThruDate.isValid()) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Nieprawidłowa wartość'
                isValid = false
            } else if (!publicationFromDate) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Należy najpierw podać początkową datę publikacji'
                isValid = false
            } else if (publicationFromDate && publicationFromDate.isValid() && publicationFromDate.isAfter(publicationThruDate)) {
                newFormData.publicationThruDate.error = true
                newFormData.publicationThruDate.helperText = 'Data początkowa nie może być późniejsza niż data końcowa'
                isValid = false
            }
        }
        
        setFormData(newFormData)
        return isValid
    }

    function updatePageVersion() {
        if (!validate()) {
            return
        }

        setInProgress(true)

        PageService.updatePageVersion({
            pageId: params.pageId,
            pageVersionId: params.pageVersionId,
            publicationFromDate: publicationFromDate,
            publicationThruDate: publicationThruDate,
        }).then(() => {
            // TODO: dodac informacje o sukcesie
            setInProgress(false)
            store.dispatch(offsetChanged(0))
            PageVersionListDataManager.load(params.pageId)
            navigate('..')
        }).catch(err => {
            console.error(err)
            setInProgress(false)
            setShowError(true)
            setErrorMessage(ErrorUtils.getMessage(err))
        })
    }

    if (!locationMatch) {
        return <Outlet/>
    }
    
    function refreshContent() {
        setInProgress(true)
        PageService.getPageVersion(params.pageId, params.pageVersionId)
            .then(r => {
                setInProgress(false)
                setContent(r.data.content)
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(e))
            })
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Edycja wersji strony
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane wersji strony</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <DateTimePicker label="Data publikacji - od"
                                                    value={publicationFromDate}
                                                    onChange={(v) => setPublicationFromDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.publicationFromDate.error,
                                                            helperText: formData.publicationFromDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <DateTimePicker label="Data publikacji - do"
                                                    value={publicationThruDate}
                                                    onChange={(v) => setPublicationThruDate(v)}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: formData.publicationThruDate.error,
                                                            helperText: formData.publicationThruDate.helperText
                                                        }
                                                    }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        updatePageVersion()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        
                        <ContentManager 
                            content={content} 
                            onAddHtmlTextItem={() => {
                                setInProgress(true)
                                PageService.addHtmlTextContentItem({
                                    pageId: params.pageId, 
                                    pageVersionId: params.pageVersionId, 
                                    html: '<p>Wprowadź treść</p>'
                                }).then((r) => {
                                    setInProgress(false)
                                    navigate('.' + Routes.Content.Item.EditHtmlTextItem.replace(':contentItemId', r.data.id))
                                }).catch((e) => {
                                    setInProgress(false)
                                    setShowError(true)
                                    setErrorMessage(ErrorUtils.getMessage(e))
                                })
                            }}
                            onAddImageItem={() => {
                                navigate('.' + Routes.Content.Item.AddImageItem)
                            }}
                            onMoveItemUp={(item) => {
                                setInProgress(true)
                                PageService.moveContentItemUp(params.pageId, params.pageVersionId, item.id)
                                    .then(() => {
                                        setInProgress(false)
                                        refreshContent()
                                    })
                                    .catch((e) => {
                                        setInProgress(false)
                                        setShowError(true)
                                        setErrorMessage(ErrorUtils.getMessage(e))
                                    })
                            }}
                            onMoveItemDown={(item) => {
                                setInProgress(true)
                                PageService.moveContentItemDown(params.pageId, params.pageVersionId, item.id)
                                    .then(() => {
                                        setInProgress(false)
                                        refreshContent()
                                    })
                                    .catch((e) => {
                                        setInProgress(false)
                                        setShowError(true)
                                        setErrorMessage(ErrorUtils.getMessage(e))
                                    })
                            }}
                            onDeleteItem={(item) => {
                                PageService.deleteContentItem(params.pageId, params.pageVersionId, item.id)
                                    .then(() => {
                                        setInProgress(false)
                                        refreshContent()
                                    })
                                    .catch(e => {
                                        setInProgress(false)
                                        setShowError(true)
                                        setErrorMessage(ErrorUtils.getMessage(e))
                                        refreshContent()
                                    })
                            }}/>
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}