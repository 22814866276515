import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import CampaignIcon from "@mui/icons-material/Campaign";
import GrassIcon from "@mui/icons-material/Grass";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import SpeedIcon from "@mui/icons-material/Speed";
import PaymentIcon from "@mui/icons-material/Payment";
import PlaceIcon from "@mui/icons-material/Place";
import GroupsIcon from "@mui/icons-material/Groups";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AttachmentIcon from "@mui/icons-material/Attachment";
import MessageIcon from "@mui/icons-material/Message";
import SettingsIcon from "@mui/icons-material/Settings";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from 'react-router-dom'
import Routes from "../../features/router/Routes";
import {useSelector} from "react-redux";

export function MainMenu() {
    const navigate = useNavigate()

    const session =  useSelector((state) => {
        return state.session
    })
    
    return (
        <>
            <nav id="main-menu">
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate(Routes.Home)}>
                            <ListItemIcon className="main-menu-item-icon">
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Strona główna"/>
                        </ListItemButton>
                    </ListItem>

                    {session && session.isPrivilegedUser &&
                    <>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(Routes.News.Index)}>
                                <ListItemIcon className="main-menu-item-icon">
                                    <NewspaperIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Aktualności"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(Routes.Notices.Index)}>
                                <ListItemIcon className="main-menu-item-icon">
                                    <CampaignIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Ogłoszenia"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                navigate(Routes.PageVersions.Index.replace(':pageId', '9b827519-939d-4188-8b7f-93ccf6704136'))
                            }}>
                                <ListItemIcon className="main-menu-item-icon">
                                    <PaymentIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Podstrona opłaty"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                navigate(Routes.PageVersions.Index.replace(':pageId', 'e171e999-4a39-4f17-bffe-30eaba021766'))
                            }}>
                                <ListItemIcon className="main-menu-item-icon">
                                    <GroupsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Podstrona władze"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                navigate(Routes.PageVersions.Index.replace(':pageId', '370efd33-8e20-4206-96d7-aa849a2196dc'))
                            }}>
                                <ListItemIcon className="main-menu-item-icon">
                                    <PlaceIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Podstrona kontakt"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                navigate(Routes.Parties.Index)
                            }}>
                                <ListItemIcon className="main-menu-item-icon">
                                    <PersonIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Podmioty"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding onClick={() => {
                            navigate(Routes.Gardens.Index)
                        }}>
                            <ListItemButton>
                                <ListItemIcon className="main-menu-item-icon">
                                    <GrassIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Działki"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(Routes.Meters.Index)}>
                                <ListItemIcon className="main-menu-item-icon">
                                    <SpeedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Liczniki"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon className="main-menu-item-icon">
                                    <PaymentIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Opłaty"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon className="main-menu-item-icon">
                                    <SwapHorizIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Transakcje"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(Routes.Files.Index)}>
                                <ListItemIcon className="main-menu-item-icon">
                                    <AttachmentIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Dokumenty"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon className="main-menu-item-icon">
                                    <MessageIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Wiadomości"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon className="main-menu-item-icon">
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="System"/>
                            </ListItemButton>
                        </ListItem>
                    </>
                    }
                </List>
            </nav>
        </>
    )
}