import React, {useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {useSelector} from "react-redux";
import ListConfig, {FilterConfig, SortConfig} from "../../components/list/ListConfig";
import GardenListFilterConfig from "../../features/gardens/list/GardenListFilterConfig";
import GardenListSortConfig from "../../features/gardens/list/GardenListSortConfig";
import GardenListDataManager from "../../features/gardens/list/GardenListDataManager";
import StandardListView, {StandardListViewItems} from "../../components/list/StandardListView";
import {store} from '../../redux/store'
import {filterByPhraseChanged, phraseChanged, offsetChanged} from "../../features/gardens/list/gardenListSlice";
import {useDebouncedCallback} from "use-debounce";
import {useNavigate, useMatch, Outlet} from "react-router-dom";
import Routes from "../../features/router/Routes";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";

function GardenListItem(props) {
    const navigate = useNavigate()
    
    return (
        <>
            <ListItem secondaryAction={
                <>
                    <IconButton onClick={() => {
                        navigate('.' + Routes.Gardens.Details.replace(':gardenId', props.data.id))
                    }}>
                        <SearchIcon/>
                    </IconButton>
                </>
            }>
                <ListItemText primary={ props.data.name } secondary={
                    <>
                        { props.data.sector.name }
                    </>
                }>
                </ListItemText>
            </ListItem>

            <Divider/>
        </>
    )
}

export function Gardens() {
    const navigate = useNavigate()
    const data = useSelector((state) => state.gardenList.data)
    const loadData = useDebouncedCallback(() => {
        GardenListDataManager.load()
    }, 1000)
    
    useEffect(() => {
        store.dispatch(offsetChanged(0))
        GardenListDataManager.load()
    }, [])
    
    if (!useMatch(Routes.Gardens.Index)) {
        return <Outlet/>
    }

    return (
        <>
            <StandardListView 
                title={"Działki"} 
                initialize={() => {
                    return {
                        showSearchInput: store.getState().gardenList.requestParams.filterByPhrase,
                        searchPhrase: store.getState().gardenList.requestParams.phrase
                    }
                }}
                onSearchButtonClick={(showSearchInput) => {
                    store.dispatch(filterByPhraseChanged(showSearchInput))
                    loadData()
                }}
                onSearchPhraseChange={(e) => {
                    store.dispatch(phraseChanged(e.target.value))
                    loadData()
                }}
                loadData={() => loadData()}
                data={data}>
                <StandardListViewItems>
                    {
                        data.items.map((i) => (
                            <GardenListItem key={i.id} data={i}/>
                        ))
                    }
                </StandardListViewItems>

                <ListConfig>
                    <FilterConfig>
                        <GardenListFilterConfig/>
                    </FilterConfig>

                    <SortConfig>
                        <GardenListSortConfig/>
                    </SortConfig>
                </ListConfig>
            </StandardListView>
        </>
    )
}