import { store } from '../../../redux/store'
import PartyService from "../PartyService";
import { dataChanged } from './partyListSlice'
import ArrayUtils from "../../../utils/ArrayUtils";
import RequestQueue from "../../../utils/net/RequestQueue";

let isInitialized = false
let queue;

if (!isInitialized) {
    isInitialized = true
    queue = new RequestQueue()
    queue.subscribe({
        success: function (r) {
            const state = store.getState()

            if (state.partyList.requestParams.offset > 0) {
                r.data.items = ArrayUtils.merge(state.partyList.data.items, r.data.items, (i) => i.id)
            }

            store.dispatch(dataChanged(r.data))
        },
        error: function (r) {
            // TODO: rozwazyc powiadomienie uzytkownika o bledzie.
            console.error(r)
        },
        complete: function (r) {
            console.debug('Stream of party list request has been completed.')
        } 
    })
}

const PartyListDataManager = {
    load: function() {
        const state = store.getState()
        queue.addRequest(PartyService.findParties(state.partyList.requestParams))
    }
}

export default PartyListDataManager