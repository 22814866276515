import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import React from "react";

export default function InProgress({open}) {
    return (
        <>
            <Backdrop open={open} color="inherit" sx={{ zIndex: 10000000 }}>
                <CircularProgress/>
            </Backdrop>
        </>
    )
}