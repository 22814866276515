import {DefaultLayout} from "../../../layouts/DefaultLayout";
import React, {useEffect, useRef, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import InProgress from "../../../components/progress/InProgress";
import FloatingAlert from "../../../components/notifications/FloatingAlert";
import {useParams} from "react-router";
import LoginService from "../../../features/security/LoginService";
import Validator from "../../../features/validation/Validator";
import PartyService from "../../../features/parties/PartyService";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import PasswordGenerator from "../../../features/security/PasswordGenerator";

export default function CreateLoginPage() {
    const navigate = useNavigate()
    const params = useParams()
    
    // Login
    const loginIdRef = useRef(null)
    const [role, setRole] = useState('-')
    const passwordRef = useRef(null)
    const [enabled, setEnabled] = useState(true)
    const [sendEmail, setSendEmail] = useState(false)
    const [sendEmailDisabled, setSendEmailDisabled] = useState(true)

    // Others
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('-')

    useEffect(() => {
        setInProgress(true)

        PartyService.getParty(params.partyId)
            .then(r => {
                setInProgress(false)
                
                if (r.data.email?.address)
                {
                    setSendEmailDisabled(false)
                }
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(e.response.data.message)
            })
    }, [params.partyId])

    function createFormData() {
        return {
            loginId: {
                error: false,
                helperText: ''
            },
            role: {
                error: false,
                helperText: ''
            },
            password: {
                error: false,
                helperText: ''
            },
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true

        const loginId = loginIdRef.current.value
        const password = passwordRef.current.value

        if (!Validator.validateLogin(loginId, true)) {
            newFormData.loginId.error = true
            newFormData.loginId.helperText = 'Login powinien składać się wyłącznie z liter i cyfr.'
            isValid = false
        }

        if (!role || role === '-') {
            newFormData.role.error = true
            newFormData.role.helperText = 'Należy podać rolę.'
            isValid = false
        }

        if (!Validator.validatePassword(password, true)) {
            newFormData.password.error = true
            newFormData.password.helperText = 'Hasło powininno składać się z co najmniej 8 znakow.'
            isValid = false
        }
        
        setFormData(newFormData)
        return isValid
    }

    function createLogin() {
        if (!validate()) {
            return
        }

        setInProgress(true)

        LoginService.createLogin({
            // Login
            partyId: params.partyId,
            loginId: loginIdRef.current.value,
            password: passwordRef.current.value,
            role: role,
            enabled: enabled,
            sendEmail: sendEmail
        }).then(() => {
            // TODO: dodac informacje o sukcesie
            setInProgress(false)
            navigate('..')
        }).catch(err => {
            console.error(err)
            setInProgress(false)
            setShowError(true)
            setErrorMessage(err.response.data.message)
        })
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Nowy login
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane loginu</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField label="Nazwa użytkownika"
                                               fullWidth={true}
                                               inputRef={loginIdRef}
                                               error={formData.loginId.error}
                                               helperText={formData.loginId.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth={true} error={formData.role.error}>
                                        <InputLabel id="role-label" shrink={true}>Rola</InputLabel>
                                        <Select labelId="role-label"
                                                label="Rola"
                                                value={role}
                                                onChange={(e) => setRole(e.target.value)}>
                                            <MenuItem value="-">(Wybierz rolę)</MenuItem>
                                            <MenuItem value="ROLE_USER">Użytkownik</MenuItem>
                                            <MenuItem value="ROLE_EMPLOYEE">Pracownik</MenuItem>
                                            <MenuItem value="ROLE_TREASURER">Skarbnik</MenuItem>
                                            <MenuItem value="ROLE_SUPER_USER">Użytkownik zaawansowany</MenuItem>
                                        </Select>
                                        <FormHelperText>{formData.role.helperText}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Hasło"
                                               type="password"
                                               autoComplete="new-password"
                                               fullWidth={true}
                                               inputRef={passwordRef}
                                               error={formData.password.error}
                                               helperText={formData.password.helperText}
                                               InputLabelProps={{
                                                   shrink: true
                                               }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="outlined"
                                                disabled={role === '-'}
                                                onClick={() => {
                                                    passwordRef.current.value = PasswordGenerator
                                                        .generate(role === 'ROLE_USER' ? 8 : 20)
                                                }}>Generuj</Button>
                                        <Button variant="outlined" 
                                                onClick={() => {
                                                    navigator.clipboard.writeText(passwordRef.current.value)
                                                }}>Kopiuj</Button>
                                    </Stack>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel 
                                            control={
                                                <Checkbox checked={enabled} onChange={() => setEnabled(!enabled)}/>
                                            } 
                                            label="Aktywny"/>
                                        <FormControlLabel 
                                            control={
                                                <Checkbox checked={sendEmail} disabled={sendEmailDisabled} 
                                                          onChange={() => setSendEmail(!sendEmail)}/>
                                            } 
                                            label="Wyślij e-mail z danymi uwierzytelniania"/>
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        createLogin()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}