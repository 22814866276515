import ListSubheader from "@mui/material/ListSubheader";
import React from "react";

export default function SortBy({children}) {
    return (
        <>
            <ListSubheader className="sort-by-header">
                Sortuj według
            </ListSubheader>
            
            {children}
        </>
    )
}