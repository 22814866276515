import { createSlice } from '@reduxjs/toolkit'
import GardenService from "../GardenService";

const initialState = {
    data: {
        totalItems: 0,
        items: []
    },
    requestParams: GardenService.createFindGardensDefaultParams()
}

export const gardenListSlice = createSlice({
    name: 'gardenList',
    initialState,
    reducers: {
        offsetChanged: (state, action) => {
            state.requestParams.offset = 0
        },
        filterByPhraseChanged: (state, action) => {
            state.requestParams.filterByPhrase = action.payload
            state.requestParams.offset = 0
        },
        filterBySectorChanged: (state, action) => {
            state.requestParams.filterBySector = action.payload
            state.requestParams.offset = 0
        },
        phraseChanged: (state, action) => {
            state.requestParams.phrase = action.payload
            state.requestParams.offset = 0
        },
        sectorChanged: (state, action) => {
            state.requestParams.sector = action.payload
            state.requestParams.offset = 0
        },
        sortByChanged: (state, action) => {
            state.requestParams.sortBy = action.payload
            state.requestParams.offset = 0
        },
        sortDirectionChanged: (state, action) => {
            state.requestParams.sortDirection = action.payload
            state.requestParams.offset = 0
        },
        dataChanged: (state, action) => {
            state.data = action.payload
            state.requestParams.offset = state.data.items.length
        }
    }
})

export const {
    offsetChanged,
    filterByPhraseChanged,
    filterBySectorChanged,
    phraseChanged,
    sectorChanged,
    sortByChanged,
    sortDirectionChanged,
    dataChanged
} = gardenListSlice.actions

export default gardenListSlice.reducer