import React, {useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useSelector} from "react-redux";
import ListConfig, {FilterConfig, SortConfig} from "../../components/list/ListConfig";
import NewsListFilterConfig from "../../features/news/list/NewsListFilterConfig";
import NewsListSortConfig from "../../features/news/list/NewsListSortConfig";
import NewsListDataManager from "../../features/news/list/NewsListDataManager";
import StandardListView, {StandardListViewItems} from "../../components/list/StandardListView";
import {store} from '../../redux/store'
import {filterByPhraseChanged, phraseChanged, offsetChanged} from "../../features/news/list/newsListSlice";
import {useDebouncedCallback} from "use-debounce";
import DateTimeFormatter from "../../utils/formatters/DateTimeFormatter";
import {useNavigate, useMatch, Outlet} from "react-router-dom";
import Routes from "../../features/router/Routes";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import NewsService from "../../features/news/NewsService";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import ErrorUtils from "../../utils/error/ErrorUtils";

function NewsListItem(props) {
    const navigate = useNavigate()

    return (
        <>
            <ListItem secondaryAction={
                <>
                    <IconButton onClick={() => {
                        navigate('.' + Routes.News.Edit.replace(':newsId', props.data.id))
                    }}>
                        <EditIcon/>
                    </IconButton>

                    <IconButton onClick={() => props.onDeleteClick(props.data)}>
                        <DeleteIcon/>
                    </IconButton>
                </>
            }>
                <ListItemText
                    primary={ props.data.title }
                    secondary={ (
                        <>
                            Data utworzenia: { DateTimeFormatter.format(props.data.createdAt) }
                            <br/>
                            Data publikacji: { DateTimeFormatter.format(props.data.publicationFromDate) } - { DateTimeFormatter.format(props.data.publicationThruDate) }
                        </>
                    )}>
                </ListItemText>
            </ListItem>

            <Divider/>
        </>
    )
}

export function NewsPage() {
    const navigate = useNavigate()
    const data = useSelector((state) => state.newsList.data)
    const [currentItem, setCurrentItem] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const loadData = useDebouncedCallback(() => {
        NewsListDataManager.load()
    }, 1000)

    useEffect(() => {
        setCurrentItem(null)
        store.dispatch(offsetChanged(0))
        NewsListDataManager.load()
    }, [])

    if (!useMatch(Routes.News.Index)) {
        return <Outlet/>
    }

    return (
        <>
            <StandardListView
                title={"Aktualności"}
                initialize={() => {
                    return {
                        showSearchInput: store.getState().newsList.requestParams.filterByPhrase,
                        searchPhrase: store.getState().newsList.requestParams.phrase
                    }
                }}
                onSearchButtonClick={(showSearchInput) => {
                    store.dispatch(filterByPhraseChanged(showSearchInput))
                    loadData()
                }}
                onSearchPhraseChange={(e) => {
                    store.dispatch(phraseChanged(e.target.value))
                    loadData()
                }}
                onAddButtonClick={() => {
                    NewsService.createNews({
                        title: 'Nowy wpis'
                    }).then((r) => {
                        console.info("The news #" + r.data.id + " has been created.")
                        store.dispatch(offsetChanged(0))
                        NewsListDataManager.load()
                        navigate('.' + Routes.News.Edit.replace(':newsId', r.data.id))
                    }).catch(err => {
                        console.error(err)
                        setShowError(true)
                        setErrorMessage(ErrorUtils.getMessage(err))
                    })
                }}
                loadData={() => loadData()}
                data={data}>
                <StandardListViewItems>
                    {
                        data.items.map((i) => (
                            <NewsListItem key={i.id} data={i} onDeleteClick={(i) => {
                                setCurrentItem(i)
                                setOpenConfirmDialog(true)
                            }}/>
                        ))
                    }
                </StandardListViewItems>

                <ListConfig>
                    <FilterConfig>
                        <NewsListFilterConfig/>
                    </FilterConfig>

                    <SortConfig>
                        <NewsListSortConfig/>
                    </SortConfig>
                </ListConfig>
            </StandardListView>

            <Dialog open={openConfirmDialog}>
                <DialogTitle>Potwierdź usunięcie zasobu</DialogTitle>
                <DialogContent dividers>
                    Czy na pewno chcesz usunąc wpis {currentItem?.title}?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setOpenConfirmDialog(false)
                    }}>
                        Anuluj
                    </Button>

                    <Button onClick={() => {
                        setOpenConfirmDialog(false)

                        NewsService.deleteNews(currentItem.id)
                            .then(() => {
                                console.info("The news #" + currentItem.id + " has been deleted.")
                                store.dispatch(offsetChanged(0))
                                NewsListDataManager.load()
                            })
                            .catch(err => {
                                console.error(err)
                                setShowError(true)
                                setErrorMessage(ErrorUtils.getMessage(err))
                            })
                    }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <FloatingAlert open={showError}
                           severity="error"
                           message={errorMessage}
                           viewHasActionButton={true}
                           onClose={() => setShowError(false)}/>
        </>
    )
}