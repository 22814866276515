import React, {useState} from "react";
import {useNavigate, useParams} from "react-router";
import NoticeService from "../../../../features/notices/NoticeService";
import ErrorUtils from "../../../../utils/error/ErrorUtils";
import {AddImageItem} from "../../../../features/content/item/ImageItem";

export default function AddImageItemInNoticePage() {
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
    const params = useParams()
    
    return <AddImageItem 
        inProgress={inProgress}
        showError={showError} 
        errorMessage={errorMessage} 
        onUploadFile={(data) => {
            setInProgress(true)
            data.noticeId = params.noticeId
            NoticeService.addImageContentItem(data)
            .then(() => {
                // TODO: dodac informacje o sukcesie
                setInProgress(false)
                navigate('..')
            }).catch(err => {
                console.error(err)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(err))
            })
        }}
        onErrorAlertClose={() => {
            setShowError(false)
        }}/>
}