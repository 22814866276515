import isEmail from "validator/es/lib/isEmail";

const Validator = {
    validateLogin: function (data, required = false) {
        if (required && (!data || data.trim().length === 0)) {
            return false
        }

        if (!data || data.trim().length === 0) {
            return true
        }

        return /^([a-z]|[A-Z]|[0-9])*$/.test(data)
    },

    validatePassword: function (data, required = false) {
        if (required && (!data || data.trim().length === 0)) {
            return false
        }

        if (!data || data.trim().length === 0) {
            return true
        }

        return data.length >= 8
    },
    
    validateEmail: function (data, required = false) {
        if (required && (!data || data.trim().length === 0)) {
            return false
        }

        if (!data || data.trim().length === 0) {
            return true
        }
        
        return isEmail(data)
    },

    validatePhone: function (data, required = false) {
        if (required && (!data || data.trim().length === 0)) {
            return false
        }

        if (!data || data.trim().length === 0) {
            return true
        }
        
        return /^[0-9]{11}$/.test(data)
    },

    validatePostalCode: function (data, required = false) {
        if (required && (!data || data.trim().length === 0)) {
            return false
        }

        if (!data || data.trim().length === 0) {
            return true
        }
        
        return /^[0-9][0-9]-[0-9][0-9][0-9]/.test(data)
    },

    validateNip: function (data, required = false) {
        if (required && (!data || data.trim().length === 0)) {
            return false
        }

        if (!data || data.trim().length === 0) {
            return true
        }
        
        return /^[0-9]{10}$/.test(data)
    },

    validateRegon: function (data, required = false) {
        if (required && (!data || data.trim().length === 0)) {
            return false
        }

        if (!data || data.trim().length === 0) {
            return true
        }
        
        return /^[0-9]{9}$/.test(data) || /^[0-9]{14}$/.test(data)
    },

    validateKrs: function (data, required = false) {
        if (required && (!data || data.trim().length === 0)) {
            return false
        }

        if (!data || data.trim().length === 0) {
            return true
        }

        const regexp = /^[0-9]{10}$/
        return regexp.test(data)
    }
}

export default Validator