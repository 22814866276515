import {DefaultLayout} from "../../layouts/DefaultLayout";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CommonButtons from "../app-bar/CommonButtons";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, {useEffect, useRef, useState} from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import DataNotFound from "../data/DataNotFound";
import TextField from "@mui/material/TextField";
import InfiniteScroll from "react-infinite-scroll-component";
import {FloatingAddButton} from "../controls/Buttons";
import {useNavigate} from "react-router";

export function StandardListViewItems({children}) {
    return <>{children}</>
}
StandardListViewItems.displayName = 'StandardListViewItems'

export default function StandardListView({
        title, 
        data,
        initialize, 
        hideSearchButton,
        onSearchPhraseChange,
        onSearchButtonClick,
        onAddButtonClick,
        loadData,
        children
    }) {
    const navigate = useNavigate()
    const [openConfig, setOpenConfig] = useState(false)
    const [StandardListViewItems, setStandardListViewItems] = useState(null);
    const [listConfig, setListConfig] = useState(null);
    const [showSearchInput, setShowSearchInput] = useState(false)
    const searchPhraseInputRef = useRef()

    useEffect(() => {
        if (initialize) {
            const initializeData = initialize()
            setShowSearchInput(initializeData.showSearchInput)
            searchPhraseInputRef.current.value = initializeData.searchPhrase
        }
        
        React.Children.map(children, (ch) => {
            if (ch.type.displayName === 'StandardListViewItems') {
                setStandardListViewItems(ch);
            }

            if (ch.type.displayName === 'ListConfig') {
                setListConfig(ch);
            }
        })
    }, [initialize, children])
    
    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>
                            
                            { !showSearchInput &&
                            <Typography variant="h1" sx={{flexGrow: 1}}>{title}</Typography>
                            }
                            
                            <TextField id="search" name="search" className="app-bar-search-input"
                                       size="small" placeholder="Wyszukaj..."
                                       variant="outlined" fullWidth={true} 
                                       sx={{ 
                                           display: showSearchInput ? 'block' : 'none' 
                                       }}
                                       inputRef={searchPhraseInputRef} onChange={(e) => {
                                           if (onSearchPhraseChange) {
                                               onSearchPhraseChange(e)
                                           }
                                       }}>
                            </TextField>

                            { !hideSearchButton &&
                                <IconButton size="large" color="inherit" edge="end"
                                            aria-label="Szukaj" aria-haspopup="false"
                                            onClick={() => {
                                                if (onSearchButtonClick) {
                                                    onSearchButtonClick(!showSearchInput)
                                                }
                                                setShowSearchInput(!showSearchInput)
                                            }}>
                                    <SearchIcon/>
                                </IconButton>
                            }
                            
                            <CommonButtons/>
                            
                            <IconButton size="large" color="inherit" edge="end"
                                        aria-label="Konfiguracja listy" aria-haspopup="true"
                                        onClick={() => { setOpenConfig(!openConfig) }}>
                                <SettingsIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <InfiniteScroll 
                        next={() => {
                            if (loadData) {
                                loadData()
                            }
                        }}
                        hasMore={ data && data.items ? data.items.length < data.totalItems : false } 
                        loader={ <p className="list-data-loading">Wczytuję dane...</p> }
                        endMessage={ data && data.items && data.items.length > 0 &&
                            <p className="list-end">Koniec listy</p>
                        }
                        dataLength={ data && data.items ? data.items.length : 0 } 
                        scrollableTarget="content-wrapper">
                        <div id="content" className="list">
                            { data && data.items && data.items.length > 0 &&
                            <List>
                                { StandardListViewItems }
                            </List>
                            }

                            { (!data || !data.items || data.items.length === 0) &&
                            <DataNotFound/>
                            }
                        </div>
                    </InfiniteScroll>
                </div>

                { onAddButtonClick &&
                    <FloatingAddButton onClick={() => {
                        onAddButtonClick()
                    }}/>
                }

                <Drawer open={openConfig} anchor="right" onClose={() => setOpenConfig(false)}>
                    { listConfig }
                </Drawer>
            </DefaultLayout>
        </>
    )
}