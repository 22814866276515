import {EditHtmlTextItem} from "../../../../../features/content/item/HtmlTextItem";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router";
import PageVersionService from "../../../../../features/pages/PageService";
import ErrorUtils from "../../../../../utils/error/ErrorUtils";

export default function EditHtmlTextItemInPageVersionPage() {
    const [inProgress, setInProgress] = useState(true)
    const [showSuccess, setShowSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate();
    const params = useParams()
    
    return <EditHtmlTextItem 
        inProgress={inProgress} 
        onInit={(editor) => {
            PageVersionService.getContentItem(params.pageId, params.pageVersionId, params.contentItemId)
                .then(r => {
                    editor.setContent(r.data.htmlText)
                    setInProgress(false)
                })
                .catch(e => {
                    setInProgress(false)
                    setShowError(true)
                    setErrorMessage(ErrorUtils.getMessage(e))
                })
        }}
        onSaveClick={(editor) => {
            setInProgress(true)
            PageVersionService.updateHtmlTextContentItem({
                pageId: params.pageId,
                pageVersionId: params.pageVersionId,
                contentItemId: params.contentItemId,
                html: editor.getContent()
            }).then(() => {
                setInProgress(false)
                setShowSuccess(true)
                setSuccessMessage('Treść zapisana')
            }).catch((e) => {
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(e))
            })
        }}
        onCommitClick={(editor) => {
            setInProgress(true)
            PageVersionService.updateHtmlTextContentItem({
                pageId: params.pageId,
                pageVersionId: params.pageVersionId,
                contentItemId: params.contentItemId,
                html: editor.getContent()
            }).then(() => {
                setInProgress(false)
                navigate('..')
            }).catch((e) => {
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(e))
            })
        }}
        showSuccess={showSuccess}
        successMessage={successMessage}
        onSuccessAlertClose={() => {
            setShowSuccess(false)
        }}
        showError={showError} 
        errorMessage={errorMessage} 
        onErrorAlertClose={() => {
            setShowError(false)
        }}/>
}